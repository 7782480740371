import React from "react";
//import './SubFooter.css';
import "./main.css"; // Estilo para o layout

function SubFooter() {
  return (
    <div className="subFooter">
      <ul>
        <li>
          <a href="https://www.instagram.com/maislaser.barradatijuca/">
            <i className="fab fa-instagram" style={{ color: "white" }}></i>{" "}
            Instagram
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/maislaser.barradatijuca">
            <i className="fab fa-facebook" style={{ color: "white" }}></i>{" "}
            Facebook
          </a>
        </li>
        <li>
          <a href="https://tr.ee/nm7I9h">
            <i className="fab fa-whatsapp" style={{ color: "white" }}></i>{" "}
            WhatsApp
          </a>
        </li>
      </ul>
    </div>
  );
}

export default SubFooter;
