import React, { useState } from "react";
//import './Pelometro.css';
import "./main.css"; // Estilo para o layout

function Pelometro() {
  const [anosDepilacao, setAnosDepilacao] = useState(0);
  const [laminasPorMes, setLaminasPorMes] = useState(0);
  const [regioesCeraPorMes, setRegioesCeraPorMes] = useState(0);

  const calcularCustoLaminas = () => {
    return anosDepilacao * laminasPorMes * 10 * 12;
  };

  const calcularCustoCera = () => {
    return anosDepilacao * regioesCeraPorMes * 40 * 12;
  };

  return (
    <section id="pelometro">
      <h2>Pelômetro</h2>
      <p>
        Olá, eu sou o pelômetro da maislaser Barra da Tijuca. Vamos calcular os
        custos da depilação com lâminas e cera? Tenho certeza que você verá quão
        vantajosa nossa depilação a laser será.
      </p>
      <div className="pelometro-container">
        <div className="image-column">
          <img src="/images/pelometro.webp" alt="Depilação Ana Hickmann" />
        </div>
        <div className="input-column">
          <div className="form-group">
            <label>Há quantos anos você se depila?</label>
            <input
              type="number"
              value={anosDepilacao}
              onChange={(e) => setAnosDepilacao(Number(e.target.value))}
              className="custom-input"
            />
          </div>

          <div className="form-group">
            <label>Quantas lâminas você utiliza por mês?</label>
            <input
              type="number"
              value={laminasPorMes}
              onChange={(e) => setLaminasPorMes(Number(e.target.value))}
              className="custom-input"
            />
          </div>

          <div className="form-group">
            <label>
              Quantas regiões do corpo você depila com cera por mês?
            </label>
            <input
              type="number"
              value={regioesCeraPorMes}
              onChange={(e) => setRegioesCeraPorMes(Number(e.target.value))}
              className="custom-input"
            />
          </div>

          <div className="extra-info">
            <div className="info-item">
              <img src="/images/lamina.jpg" alt="lamina-icone" />
              <p>
                Custo estimado com lâminas: R${" "}
                {calcularCustoLaminas().toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
            <div className="info-item">
              <img src="/images/cera.jpg" alt="cera-icone" />
              <p>
                Custo estimado com cera: R${" "}
                {calcularCustoCera().toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Pelometro;
