import React from "react";
//import './Contato.css'; // Estilo para o layout
import "./main.css"; // Estilo para o layout

function Contato() {
  return (
    <section id="contato">
      <div className="contato-container">
        {/* Lado esquerdo */}
        <div className="contato-left">
          <h2>
            Estamos pertinho de você, <br /> fale conosco. 🤍
          </h2>
          <div className="contato-telefones-email">
            <p>
              <i className="fas fa-envelope" style={{ color: "white" }}></i>
              <a
                href="mailto:rj.barradatijuca.comercial@maislaser.com.br"
                style={{ color: "white" }}
              >
                rj.barradatijuca.comercial@maislaser.com.br
              </a>
            </p>
            <p>
              <i className="fas fa-mobile-alt" style={{ color: "white" }}></i>
              <a href="tel:+5521991261684" style={{ color: "white" }}>
                (21) 99126-1684
              </a>
            </p>
            <p>
              <i className="fas fa-phone" style={{ color: "white" }}></i>
              <a href="tel:+552121375562" style={{ color: "white" }}>
                (21) 2137-5562
              </a>
            </p>
            <br />
            <p>
              <i className="fas fa-clock" style={{ color: "white" }}></i>
              Seg - Sex: 09:00 a 20:00
            </p>
            <p>
              <i className="fas fa-clock" style={{ color: "white" }}></i>
              Sábado: 08:30 a 17:30
            </p>
          </div>
        </div>

        {/* Lado direito */}
        <div className="contato-mapa">
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.748033356557!2d-43.377022784520625!3d-22.99971768495495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x997e257843d1a1%3A0xa6f0b4a9d4ff5f0e!2sAv.%20das%20Am%C3%A9ricas%2C%2012300%20-%20Barra%20da%20Tijuca%2C%20Rio%20de%20Janeiro%20-%20RJ%2C%2022750-902%2C%20Brazil!5e0!3m2!1sen!2sus!4v1639136168289!5m2!1sen!2sus"
            width="100%"
            height="350"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
          <p>Av. das Américas, 12300 - Barra da Tijuca, Rio de Janeiro, RJ</p>
          <p>Blue Center Mall - Loja 170</p>
        </div>
      </div>
    </section>
  );
}

export default Contato;
