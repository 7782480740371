// NotFound.js
import React from "react";
import "./NotFound.css";

function NotFound() {
  return (
    <div id="not-found">
      <h1>404 - Página não encontrada</h1>
      <p>A página que você está procurando não existe.</p>
    </div>
  );
}

export default NotFound;
