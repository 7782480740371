import React, { useState, useEffect } from "react";
import { db } from "../config/firebase";
import {
  collection,
  addDoc,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";

function FolhaSalarial() {
  const funcionarias = ["Ana", "Carol", "Enny", "Isabella", "Fabiana"];
  const [diasUteis, setDiasUteis] = useState({});
  const [sabados, setSabados] = useState({});
  const [mesSelecionado, setMesSelecionado] = useState("");
  const [folhaSalarial, setFolhaSalarial] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const valorAlmoco = 22.12;
  const valorTransporte = 8.6;

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "folhasalarial"), orderBy("mes", "asc")),
      (snapshot) => {
        const dados = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFolhaSalarial(dados);
        setCarregando(false);
      },
      (error) => {
        console.error("Erro ao buscar dados:", error);
        setCarregando(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const handleDiasUteisChange = (nome, value) => {
    const numericValue = parseInt(value) || 0;
    if (numericValue < 0) return;

    setDiasUteis((prevDias) => ({
      ...prevDias,
      [nome]: numericValue,
    }));
  };

  const handleSabadosChange = (nome, value) => {
    const numericValue = parseInt(value) || 0;
    if (numericValue < 0) return;

    setSabados((prevSabados) => ({
      ...prevSabados,
      [nome]: numericValue,
    }));
  };

  const calcularTotal = (nome) => {
    const diasTrabalhados = (diasUteis[nome] || 0) + (sabados[nome] || 0);
    let total = 0;

    if (nome === "Fabiana") {
      const diasSemana = diasUteis[nome] || 0;
      const diasSabado = sabados[nome] || 0;
      total =
        diasSemana * (valorAlmoco + valorTransporte) +
        diasSabado * valorTransporte;
    } else {
      total = diasTrabalhados * (valorAlmoco + valorTransporte);
    }

    return total;
  };

  const salvarFolhaSalarial = async () => {
    if (!mesSelecionado) {
      alert("Por favor, selecione um mês.");
      return;
    }

    const mesExistente = folhaSalarial.find(
      (folha) => folha.mes === mesSelecionado
    );

    if (mesExistente) {
      alert("Os dados para este mês já foram lançados.");
      return;
    }

    try {
      const dadosParaSalvar = {
        mes: mesSelecionado,
        funcionarias: funcionarias.map((nome) => ({
          nome,
          diasUteis: diasUteis[nome] || 0,
          sabados: sabados[nome] || 0,
          valorAlmoco,
          valorTransporte,
          total: calcularTotal(nome),
        })),
      };

      await addDoc(collection(db, "folhasalarial"), dadosParaSalvar);

      setDiasUteis({});
      setSabados({});
      setMesSelecionado("");
      setIsModalOpen(false);

      alert("Folha salarial salva com sucesso!");
    } catch (error) {
      console.error("Erro ao salvar folha salarial:", error);
      alert("Erro ao salvar. Tente novamente.");
    }
  };

  if (carregando) {
    return <p>Carregando dados...</p>;
  }

  return (
    <div>
      <h2>Folha Salarial</h2>
      <button onClick={() => setIsModalOpen(true)}>Lançar Auxílios</button>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Lançar Auxílio Alimentação e Transporte</h3>
            <label htmlFor="mes">Selecione o Mês:</label>
            <select
              id="mes"
              value={mesSelecionado}
              onChange={(e) => setMesSelecionado(e.target.value)}
            >
              <option value="">Selecione</option>
              <option value="Outubro">Outubro</option>
              <option value="Novembro">Novembro</option>
              <option value="Dezembro">Dezembro</option>
              {/* Adicione mais meses conforme necessário */}
            </select>

            {funcionarias.map((nome) => (
              <div key={nome}>
                <label>{nome} - Dias Úteis:</label>
                <input
                  type="number"
                  value={diasUteis[nome] || 0}
                  onChange={(e) => handleDiasUteisChange(nome, e.target.value)}
                />
                <label>{nome} - Sábados:</label>
                <input
                  type="number"
                  value={sabados[nome] || 0}
                  onChange={(e) => handleSabadosChange(nome, e.target.value)}
                />
              </div>
            ))}

            <button onClick={salvarFolhaSalarial}>Salvar</button>
            <button onClick={() => setIsModalOpen(false)}>Cancelar</button>
          </div>
        </div>
      )}

      <table>
        <thead>
          <tr>
            <th>Nome</th>
            {folhaSalarial.map((folha) => (
              <th key={folha.mes}>{folha.mes}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {funcionarias.map((nome) => (
            <tr key={nome}>
              <td>{nome}</td>
              {folhaSalarial.map((folha) => {
                const func = folha.funcionarias.find((f) => f.nome === nome);
                return (
                  <td key={folha.mes}>
                    R$ {func ? func.total.toFixed(2) : "0.00"}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default FolhaSalarial;
