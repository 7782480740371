// Login.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  getIdTokenResult,
} from "firebase/auth";
import { auth } from "../config/firebase"; // Importe o objeto auth
import { configureUserClaims } from "./claimsManager"; // Importe a função para configurar claims

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const isWithinSchedule = (claims) => {
    if (!claims.schedule) return true; // Se não houver schedule, permite o acesso

    const { days, startTime, endTime } = claims.schedule;
    const now = new Date();
    const currentDay = now
      .toLocaleString("en-US", { weekday: "long" })
      .toLowerCase();
    const currentMinutes = now.getHours() * 60 + now.getMinutes();

    // Verifica se o dia atual está nos dias permitidos
    if (!days.includes(currentDay)) return false;

    // Verifica se o horário atual está dentro do intervalo permitido
    return currentMinutes >= startTime && currentMinutes <= endTime;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Force a atualização do token após o login bem-sucedido
      const idTokenResult = await getIdTokenResult(user, true);
      const claims = idTokenResult.claims; // Corrigido: Definindo claims corretamente
      console.log("Claims frescas:", claims);

      // Verifica se o usuário está dentro do horário permitido
      if (!isWithinSchedule(claims)) {
        setError("Acesso não permitido fora do horário de trabalho.");
        return;
      }

      console.log("Login bem-sucedido!");

      // Chama a função para configurar claims
      await configureUserClaims();

      // Redireciona para a página inicial ou outra página
      navigate("/administrativo");
    } catch (err) {
      setError("Erro ao fazer login. Verifique suas credenciais.");
      console.error("Erro no login:", err);
    }
  };

  return (
    <div className="login">
      <div className="login-left">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label>
              Usuário:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
            </label>
          </div>
          <div className="input-group">
            <label>
              Senha:
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Senha"
                required
              />
            </label>
          </div>
          <button type="submit">Entrar</button>
          {error && <p>{error}</p>}
        </form>
      </div>
      <div className="login-right">
        <img src="/images/login.webp" alt="login-ana" />
      </div>
    </div>
  );
};

export default Login;
