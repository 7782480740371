import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../config/firebase.js";
import {
  onAuthStateChanged,
  signOut,
  setPersistence,
  browserLocalPersistence,
  getIdTokenResult,
} from "firebase/auth";

const FirebaseAuthContext = createContext(null);

export const useFirebaseAuth = () => useContext(FirebaseAuthContext);

export const FirebaseAuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [customClaims, setCustomClaims] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingClaims, setLoadingClaims] = useState(true);

  useEffect(() => {
    const setupAuth = async () => {
      try {
        await setPersistence(auth, browserLocalPersistence);
      } catch (error) {
        console.error("Erro ao configurar persistência:", error);
      }

      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          try {
            const idTokenResult = await getIdTokenResult(user, true);
            console.log("Claims depois da atualização:", idTokenResult.claims);
            setCurrentUser(user);
            setCustomClaims(idTokenResult.claims);
          } catch (error) {
            console.error("Erro ao obter claims:", error);
            setCustomClaims(null);
          } finally {
            setLoading(false);
            setLoadingClaims(false);
          }
        } else {
          setCurrentUser(null);
          setCustomClaims(null);
          setLoading(false);
          setLoadingClaims(false);
        }
      });

      return () => unsubscribe();
    };

    setupAuth();
  }, []);

  const logout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
      setCustomClaims(null);
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };

  const setLoginDate = () => {
    const today = new Date().toISOString().split("T")[0];
    localStorage.setItem("lastLoginDate", today);
  };

  const value = {
    currentUser,
    customClaims,
    logout,
    setLoginDate,
    loading,
    loadingClaims,
  };

  return (
    <FirebaseAuthContext.Provider value={value}>
      {loading ? <div>Carregando...</div> : children}{" "}
      {/* Exibe "Carregando..." enquanto loading for true */}
    </FirebaseAuthContext.Provider>
  );
};
