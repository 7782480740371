import React, { useState, useEffect, useMemo } from "react";
import { db } from "../config/firebase"; // Certifique-se de que o caminho está correto
import {
  collection,
  addDoc,
  doc,
  arrayUnion,
  getDoc,
  getFirestore,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import { useFirebaseAuth } from "./FirebaseAuthContext";
import { getAuth } from "firebase/auth";
import Select from "react-select";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import "./GestaoLeads.css";

const capitalizeName = (name) => {
  return name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const getCurrentMonth = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  return `${year}-${month}`;
};

const GestaoLeads = () => {
  const [leads, setLeads] = useState([]);
  const [newLead, setNewLead] = useState({
    nome: "",
    telefone: "",
    etiqueta: [],
    dataInteracao: "",
    consultora: "",
  });
  const { currentUser } = useFirebaseAuth();
  const [isAdding, setIsAdding] = useState(false); // Estado para controlar a exibição do modal
  const [isChartVisible, setIsChartVisible] = useState(false); // Estado para controlar a visibilidade do gráfico

  const [leadsPerDay, setLeadsPerDay] = useState({});
  const [isShowingAllLeads, setIsShowingAllLeads] = useState(false);

  const [isAdmin, setIsAdmin] = useState(false); // Estado para armazenar se o usuário é admin
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar o modal
  const [selectedLead, setSelectedLead] = useState(null); // Lead atualmente selecionado
  const [newStatus, setNewStatus] = useState(""); // Novo status selecionado]
  const [isNameModalOpen, setIsNameModalOpen] = useState(false); // Controla o modal de nome
  const [editedName, setEditedName] = useState(""); // Armazena o nome editado
  const [filterName, setFilterName] = useState("");
  const [filterPhone, setFilterPhone] = useState("");
  const [filterEtiqueta, setFilterEtiqueta] = useState("");
  const [filterDataInteracao, setFilterDataInteracao] = useState(
    getCurrentMonth()
  );
  const [filterConsultora, setFilterConsultora] = useState("");
  const [filterStatus, setFilterStatus] = useState("Em contato||Aberto"); // Filtro inicial
  const [leadsCache, setLeadsCache] = useState(null);
  const [editedConsultora, setEditedConsultora] = useState("");
  const [isConsultoraModalOpen, setIsConsultoraModalOpen] = useState(false);

  const exportFilteredLeadsToExcel = async () => {
    // Cria um novo workbook e uma worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Leads Filtrados");

    // Adiciona cabeçalhos na worksheet
    worksheet.columns = [
      { header: "Nome", key: "nome", width: 20 },
      { header: "Telefone", key: "telefone", width: 15 },
      { header: "Etiqueta", key: "etiqueta", width: 30 },
      { header: "Data de Interação", key: "dataInteracao", width: 15 },
      { header: "Carteira", key: "consultora", width: 15 },
      { header: "Status", key: "status", width: 15 },
    ];

    // Adiciona as linhas (dados filtrados)
    filteredLeads.forEach((lead) => {
      worksheet.addRow({
        nome: lead.nome,
        telefone: lead.telefone,
        etiqueta: Array.isArray(lead.etiqueta)
          ? lead.etiqueta.join(", ")
          : lead.etiqueta,
        dataInteracao: lead.dataInteracao,
        consultora: lead.consultora,
        status: lead.status || "Aberto",
      });
    });

    // Gera o arquivo Excel em formato Blob
    const buffer = await workbook.xlsx.writeBuffer();

    // Salva o arquivo usando FileSaver.js
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      "leads_filtrados.xlsx"
    );
  };

  const getFirstDayOfMonth = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Mês começa em 0
    return `${year}-${month}-01`; // Primeiro dia do mês
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Mês começa em 0
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [startDate, setStartDate] = useState(getFirstDayOfMonth()); // Data de início padrão
  const [endDate, setEndDate] = useState(getTodayDate()); // Data de término padrão

  // Função para formatar a data ISO para o formato brasileiro
  const formatISOToDatabaseDate = (isoDate) => {
    const [year, month, day] = isoDate.split("-");
    return `${day}/${month}/${year}`;
  };

  const formatDateToISO = (date) => {
    if (!date || typeof date !== "string") return null;
    const parts = date.split("/");
    if (parts.length !== 3) return null;
    const [day, month, year] = parts;
    return `${year}-${month}-${day}`;
  };

  // Função para exportar leadsCount para Excel
  const exportLeadsCountToExcel = async () => {
    // Transforma leadsCount em um array de objetos
    const data = Object.keys(leadsPerDay).map((date) => ({
      Dia: date,
      Contagem: leadsPerDay[date],
    }));

    // Cria um novo workbook e uma worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Leads Count");

    // Adiciona cabeçalhos na worksheet
    worksheet.columns = [
      { header: "Dia", key: "Dia", width: 15 },
      { header: "Contagem", key: "Contagem", width: 10 },
    ];

    // Adiciona as linhas (dados)
    worksheet.addRows(data);

    // Gera o arquivo Excel em formato Blob
    const buffer = await workbook.xlsx.writeBuffer();

    // Salva o arquivo usando FileSaver.js
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      "leads_count.xlsx"
    );
  };

  const filteredLeads = useMemo(() => {
    return leads.filter((lead) => {
      // Filtro por nome
      const matchesName = filterName
        ? lead.nome.toLowerCase().includes(filterName.toLowerCase())
        : true;

      // Filtro por telefone
      const matchesPhone = filterPhone
        ? lead.telefone.includes(filterPhone)
        : true;

      // Filtro por etiqueta
      const matchesEtiqueta = filterEtiqueta
        ? Array.isArray(lead.etiqueta)
          ? lead.etiqueta.some((etiqueta) => etiqueta === filterEtiqueta)
          : lead.etiqueta === filterEtiqueta
        : true;

      // Filtro por mês de interação
      const matchesDataInteracao = (() => {
        if (!filterDataInteracao) return true; // Se não houver filtro, retorna todos

        const [filterYear, filterMonth] = filterDataInteracao.split("-");
        const [day, month, year] = lead.dataInteracao.split("/");

        return filterYear === year && filterMonth === month;
      })();

      // Filtro por consultora
      const matchesConsultora = filterConsultora
        ? lead.consultora === filterConsultora
        : true;

      // Filtro por status
      const matchesStatus = filterStatus
        ? lead.status === filterStatus || filterStatus.includes(lead.status)
        : true;

      return (
        matchesName &&
        matchesPhone &&
        matchesEtiqueta &&
        matchesDataInteracao &&
        matchesConsultora &&
        matchesStatus
      );
    });
  }, [
    leads,
    filterName,
    filterPhone,
    filterEtiqueta,
    filterDataInteracao,
    filterConsultora,
    filterStatus,
    startDate, // Dependência adicionada
    endDate, // Dependência adicionada
  ]);

  const updateLeadName = async () => {
    if (selectedLead && editedName.trim()) {
      try {
        await updateDoc(doc(db, "leads", selectedLead.id), {
          nome: editedName.trim(),
          log: arrayUnion({
            date: new Date().toISOString(),
            action: `Nome alterado de "${
              selectedLead.nome
            }" para "${editedName.trim()}"`,
            user: currentUser?.email || "Usuário não autenticado",
          }),
        });
        setIsNameModalOpen(false); // Fecha o modal após atualizar
        setSelectedLead(null);
        setEditedName("");
      } catch (error) {
        console.error("Erro ao atualizar o nome do lead:", error);
      }
    } else {
      alert("O nome não pode estar vazio!");
    }
  };

  // Função para formatar e contar leads por dia no intervalo especificado
  const countLeadsByDate = (leadsData) => {
    console.log("Leads recebidos:", leadsData); // Verifica se os leads estão chegando corretamente
    console.log("StartDate:", startDate, "EndDate:", endDate);

    const start = new Date(startDate);
    const end = new Date(endDate);

    const leadsInRange = leadsData.filter((lead) => {
      const [day, month, year] = lead.dataInteracao.split("/");
      const leadDate = new Date(`${year}-${month}-${day}`);
      console.log(
        "Data do lead:",
        lead.dataInteracao,
        "Data convertida:",
        leadDate
      );

      const hasValidEtiqueta = Array.isArray(lead.etiqueta)
        ? lead.etiqueta.includes("Anúncio Whatsapp") ||
          lead.etiqueta.includes("Anúncio Instagram")
        : false;

      return leadDate >= start && leadDate <= end && hasValidEtiqueta;
    });

    console.log("Leads no intervalo:", leadsInRange);

    const leadsCount = {};
    for (let d = new Date(start); d <= end; d.setDate(d.getDate() + 1)) {
      const dateStr = d.toISOString().split("T")[0];
      const formattedDate = dateStr.split("-").reverse().join("/");
      leadsCount[formattedDate] = leadsInRange.filter(
        (lead) => lead.dataInteracao === formattedDate
      ).length;
    }

    console.log("Leads por dia:", leadsCount);
    setLeadsPerDay(leadsCount);
  };

  useEffect(() => {
    if (leads.length > 0) {
      countLeadsByDate(leads);
    }
  }, [leads]); // Sempre que 'leads' mudar, recalcula a contagem.

  // Função para atualizar o nome da consultora no Firestore
  const updateConsultora = async () => {
    if (selectedLead && editedConsultora) {
      try {
        await updateDoc(doc(db, "leads", selectedLead.id), {
          consultora: editedConsultora,
        });

        // Atualiza a lista de leads localmente (opcional, mas recomendado para melhor UX)
        setLeads((prevLeads) =>
          prevLeads.map((lead) =>
            lead.id === selectedLead.id
              ? { ...lead, consultora: editedConsultora }
              : lead
          )
        );

        setIsConsultoraModalOpen(false);
        setSelectedLead(null);
        setEditedConsultora("");
      } catch (error) {
        console.error("Erro ao atualizar a consultora:", error);
        // Lidar com o erro, talvez exibir uma mensagem para o usuário
      }
    }
  };

  // Calcula a média de leads por dia no intervalo
  const totalLeads = Object.values(leadsPerDay).reduce(
    (sum, count) => sum + count,
    0
  );
  const daysInRange = Object.keys(leadsPerDay).length;
  const averageLeads = daysInRange > 0 ? totalLeads / daysInRange : 0;

  // Prepara os dados para as linhas de média e mínimo esperado
  const averageLine = Array(daysInRange).fill(averageLeads);
  const minimumLine = Array(daysInRange).fill(8); // Linha de mínimo esperado de 8

  const etiquetaOptions = [
    { value: "Anúncio Whatsapp", label: "Anúncio Whatsapp" },
    { value: "Anúncio Instagram", label: "Anúncio Instagram" },
    { value: "Instagram", label: "Instagram" },
    { value: "Google", label: "Google" },
    { value: "Depilação-Laser", label: "Depilação-Laser" },
    { value: "Remoc.Tattoo", label: "Remoc.Tattoo" },
    { value: "Clareamento", label: "Clareamento" },
    { value: "Passante", label: "Passante" },
  ];

  const carteiraOptions = [
    { value: "Ana", label: "Ana" },
    { value: "Carolina", label: "Carolina" },
    { value: "Enny", label: "Enny" },
    { value: "Isabella", label: "Isabella" },
  ];

  // Função para formatar o número de telefone
  const formatPhoneNumber = (phone) => {
    if (!phone) return ""; // Retorna vazio se o telefone não estiver presente

    // Remove todos os caracteres não numéricos
    const cleaned = phone.replace(/\D/g, "");

    // Formata para o padrão (XX) XXXXX-XXXX
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phone;
  };

  // Função para adicionar um novo lead
  const addLead = async (e) => {
    e.preventDefault();

    try {
      // Formata o telefone antes de salvar
      const formattedPhone = formatPhoneNumber(newLead.telefone);

      // Adiciona o lead ao Firestore
      const leadDoc = await addDoc(collection(db, "leads"), {
        ...newLead,
        telefone: formattedPhone,
        etiqueta: newLead.etiqueta.map((option) => option.value),
        dataInteracao: newLead.dataInteracao, // Salva no formato DD/MM/YYYY
        status: "Aberto", // Adiciona o status inicial
      });

      // Atualiza o estado localmente com o novo lead
      const addedLead = {
        id: leadDoc.id,
        ...newLead,
        telefone: formattedPhone,
      };

      setLeadsCache((prevCache) => {
        const updatedCache = [...(prevCache || []), addedLead];
        return updatedCache.sort((a, b) => {
          const dateA = new Date(formatDateToISO(a.dataInteracao));
          const dateB = new Date(formatDateToISO(b.dataInteracao));
          return dateA - dateB; // Ordem crescente
        });
      });

      setLeads((prevLeads) => {
        const updatedLeads = [...(prevLeads || []), addedLead];
        return updatedLeads.sort((a, b) => {
          const dateA = new Date(formatDateToISO(a.dataInteracao));
          const dateB = new Date(formatDateToISO(b.dataInteracao));
          return dateA - dateB; // Ordem crescente
        });
      });

      // Recalcula a contagem de leads por data para atualizar o gráfico
      countLeadsByDate([...leads, addedLead]);

      // Limpa o formulário e fecha o modal
      setNewLead({
        nome: "",
        telefone: "",
        etiqueta: [],
        dataInteracao: "",
        consultora: "",
      });
      setIsAdding(false);
    } catch (error) {
      console.error("Erro ao cadastrar o lead:", error);
    }
  };

  // Função para atualizar o status no Firestore
  const updateLeadStatus = async () => {
    if (selectedLead && newStatus) {
      try {
        await updateDoc(doc(db, "leads", selectedLead.id), {
          status: newStatus,
          log: arrayUnion({
            date: new Date().toISOString(),
            action: `Status alterado de "${
              selectedLead.status || "Aberto"
            }" para "${newStatus}"`,
            user: currentUser?.email || "Usuário não autenticado",
          }),
        });
        setIsModalOpen(false); // Fechar o modal após atualizar
        setSelectedLead(null);
        setNewStatus("");
      } catch (error) {
        console.error("Erro ao atualizar o status do lead:", error);
      }
    }
  };

  // Verificar o papel do usuário logado
  useEffect(() => {
    const fetchUserRole = async () => {
      const auth = getAuth(); // Firebase Authentication
      const currentUser = auth.currentUser; // Usuário logado

      if (currentUser) {
        try {
          // Referência ao documento do usuário no Firestore
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setIsAdmin(userData.role === "admin"); // Verifica se o papel é admin
          } else {
            console.warn("Documento do usuário não encontrado!");
          }
        } catch (error) {
          console.error("Erro ao buscar o papel do usuário:", error);
        }
      } else {
        console.warn("Usuário não está logado!");
      }
    };

    fetchUserRole();
  }, []);

  // Uso de Cache para Leitura de Leads
  useEffect(() => {
    const fetchLeads = async () => {
      const leadsRef = collection(db, "leads");

      const unsubscribe = onSnapshot(leadsRef, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const newLead = { id: change.doc.id, ...change.doc.data() };

            // Verifica se o lead já existe antes de adicionar
            setLeads((prevLeads) => {
              const alreadyExists = prevLeads.some(
                (lead) => lead.id === newLead.id
              );
              if (!alreadyExists) {
                return [...prevLeads, newLead].sort((a, b) => {
                  const dateA = new Date(formatDateToISO(a.dataInteracao));
                  const dateB = new Date(formatDateToISO(b.dataInteracao));
                  return dateA - dateB; // Ordem crescente
                });
              }
              return prevLeads; // Não adiciona duplicatas
            });
          }

          if (change.type === "modified") {
            const updatedLead = { id: change.doc.id, ...change.doc.data() };

            // Atualiza apenas o lead modificado
            setLeads((prevLeads) =>
              prevLeads.map((lead) =>
                lead.id === updatedLead.id ? updatedLead : lead
              )
            );
          }
        });

        countLeadsByDate(leads); // Recalcula os dados do gráfico
      });

      return () => unsubscribe();
    };

    if (!leadsCache) {
      fetchLeads();
    }
  }, [leadsCache]);

  const getLeadsForMonth = (leads) => {
    return leads.filter((lead) => {
      if (!filterDataInteracao) return true;

      const [filterYear, filterMonth] = filterDataInteracao.split("-");
      const [day, month, year] = lead.dataInteracao.split("/");

      return filterYear === year && filterMonth === month;
    });
  };

  const leadsForMonth = useMemo(
    () => getLeadsForMonth(leads),
    [leads, filterDataInteracao]
  );

  const anaCount = leadsForMonth.filter(
    (lead) => lead.consultora === "Ana"
  ).length;
  const carolinaCount = leadsForMonth.filter(
    (lead) => lead.consultora === "Carolina"
  ).length;
  const ennyCount = leadsForMonth.filter(
    (lead) => lead.consultora === "Enny"
  ).length;
  const isabellaCount = leadsForMonth.filter(
    (lead) => lead.consultora === "Isabella"
  ).length;

  const data = {
    labels: Object.keys(leadsPerDay),
    datasets: [
      {
        label: "Número de Leads por Dia",
        data: Object.values(leadsPerDay),
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
      },
      {
        label: "Média de Leads por Dia",
        data: averageLine,
        borderColor: "rgba(54, 162, 235, 1)",
        borderDash: [5, 5],
        fill: false,
      },
      {
        label: "Mínimo Esperado (8)",
        data: minimumLine,
        borderColor: "rgba(255, 99, 132, 1)",
        borderDash: [5, 5],
        fill: false,
      },
    ],
  };

  return (
    <div className="gestao-leads-container">
      <h2>Gestão dos Leads e potenciais clientes</h2>
      <div className="contador-anotacoes">
        <div className="contador-box">
          <p className="contador-titulo">Ana</p>
          <p className="contador-numero">{anaCount}</p>
        </div>
        <div className="contador-box">
          <p className="contador-titulo">Carolina</p>
          <p className="contador-numero">{carolinaCount}</p>
        </div>
        <div className="contador-box">
          <p className="contador-titulo">Enny</p>
          <p className="contador-numero">{ennyCount}</p>
        </div>
        <div className="contador-box">
          <p className="contador-titulo">Isabella</p>
          <p className="contador-numero">{isabellaCount}</p>
        </div>
      </div>
      <div className="button-container">
        <button
          className="cadastrar-lead-btn"
          onClick={() => setIsAdding(true)}
        >
          Cadastrar Carteira
        </button>
        {isAdmin && (
          <>
            <button
              className="cadastrar-lead-btn"
              onClick={exportLeadsCountToExcel}
            >
              Baixar Contador de Leads Whatsapp e Instagram
            </button>
            <button
              className="cadastrar-lead-btn"
              onClick={exportFilteredLeadsToExcel}
            >
              Baixar Tabela Filtrada
            </button>
            <button
              className="cadastrar-lead-btn"
              onClick={() => setIsChartVisible(!isChartVisible)}
            >
              {isChartVisible
                ? "Fechar Gráfico de Leads"
                : "Mostrar Gráfico de Leads"}
            </button>
            <button
              className="cadastrar-lead-btn"
              onClick={() => {
                if (isShowingAllLeads) {
                  // Volta para exibir apenas leads do mês atual
                  setFilterDataInteracao(getCurrentMonth());
                } else {
                  // Remove o filtro de mês para mostrar todo o histórico
                  setFilterDataInteracao("");
                }
                setIsShowingAllLeads((prevState) => !prevState); // Alterna o estado
              }}
            >
              {isShowingAllLeads
                ? "Mostrar Apenas Leads do Mês"
                : "Mostrar Todo Histórico de Leads"}
            </button>
          </>
        )}
      </div>

      {/* Filtros de data */}
      {isChartVisible && (
        <div>
          <div className="date-filters">
            <div className="date-input-wrapper">
              <label>Data de Início:</label>
              <div className="input-with-icon">
                <input
                  className="date-input"
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
            </div>
            <div className="date-input-wrapper">
              <label>Data de Fim:</label>
              <div className="input-with-icon">
                <input
                  className="date-input"
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Renderizando o gráfico */}
          <div className="lead-chart">
            <h3>
              Leads de {startDate} a {endDate}
            </h3>
            <Line data={data} />
          </div>
        </div>
      )}

      {isAdding && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Cadastrar Lead ou Potenciais Clientes</h3>
            <form onSubmit={addLead}>
              <label>Nome:</label>
              <input
                type="text"
                placeholder="Nome"
                value={newLead.nome}
                onChange={(e) =>
                  setNewLead({
                    ...newLead,
                    nome: capitalizeName(e.target.value),
                  })
                }
                required
              />

              <label>Telefone:</label>
              <input
                type="text"
                placeholder="(XX) XXXXX-XXXX"
                value={newLead.telefone}
                onChange={(e) =>
                  setNewLead({ ...newLead, telefone: e.target.value })
                }
              />

              <label>Etiqueta:</label>
              <Select
                isMulti
                options={etiquetaOptions}
                value={newLead.etiqueta}
                onChange={(selectedOptions) =>
                  setNewLead({ ...newLead, etiqueta: selectedOptions })
                }
              />

              <label>Data de Interação:</label>
              <input
                type="date"
                value={
                  newLead.dataInteracao
                    ? newLead.dataInteracao.split("/").reverse().join("-") // Converte DD/MM/YYYY para YYYY-MM-DD
                    : ""
                }
                onChange={(e) => {
                  const isoDate = e.target.value; // Valor no formato YYYY-MM-DD
                  const [year, month, day] = isoDate.split("-");
                  const formattedDate = `${day}/${month}/${year}`; // Converte para DD/MM/YYYY
                  setNewLead({ ...newLead, dataInteracao: formattedDate });
                }}
                required
              />
              <label>Carteira:</label>
              <Select
                options={carteiraOptions}
                value={carteiraOptions.find(
                  (option) => option.value === newLead.consultora
                )}
                onChange={(selectedOption) =>
                  setNewLead({ ...newLead, consultora: selectedOption.value })
                }
                placeholder="Selecione a carteira"
                required
              />

              <button type="submit">Salvar Lead</button>
              <button type="button" onClick={() => setIsAdding(false)}>
                Cancelar
              </button>
            </form>
          </div>
        </div>
      )}

      <h2>Lista de Leads e Potenciais Clientes Cadastrados</h2>
      <div className="table-responsive">
        <div className="filters">
          <input
            type="text"
            placeholder="Filtrar por Nome"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Filtrar por Telefone"
            value={filterPhone}
            onChange={(e) => setFilterPhone(e.target.value)}
          />
          <Select
            options={etiquetaOptions} // Lista de opções fornecida
            value={etiquetaOptions.find(
              (option) => option.value === filterEtiqueta
            )} // Seleciona a etiqueta
            onChange={(selectedOption) =>
              setFilterEtiqueta(selectedOption ? selectedOption.value : "")
            }
            placeholder="Filtrar por Etiqueta"
            isClearable // Ativa o "x" para limpar o filtro
          />
          {!isShowingAllLeads && (
            <input
              type="month"
              placeholder="Filtrar por Mês de Interação"
              value={filterDataInteracao}
              onChange={(e) => setFilterDataInteracao(e.target.value)}
            />
          )}
          <Select
            options={carteiraOptions}
            placeholder="Filtrar por Carteira"
            onChange={(selectedOption) =>
              setFilterConsultora(selectedOption ? selectedOption.value : "")
            }
            isClearable // Ativa o "x" para limpar o filtro
          />
          <Select
            options={[
              { value: "Em contato", label: "Em contato" },
              { value: "Aberto", label: "Aberto" },
              { value: "Perdeu Interesse", label: "Perdeu Interesse" },
              { value: "Comprou", label: "Comprou" },
            ]}
            isClearable // Ativa o "x" para limpar o filtro
            placeholder="Filtrar por Status"
            onChange={(selectedOption) =>
              setFilterStatus(selectedOption ? selectedOption.value : "")
            }
          />
        </div>

        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Telefone</th>
              <th>Etiqueta</th>
              <th>Data de Interação</th>
              <th>Carteira</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredLeads.map((lead) => (
              <tr key={lead.id}>
                <td
                  onDoubleClick={() => {
                    setSelectedLead(lead); // Define o lead selecionado
                    setEditedName(lead.nome); // Define o nome atual
                    setIsNameModalOpen(true); // Abre o modal de edição de nome
                  }}
                >
                  {lead.nome}
                </td>
                <td>{formatPhoneNumber(lead.telefone)}</td>{" "}
                {/* Exibe o telefone formatado */}
                <td>
                  {Array.isArray(lead.etiqueta)
                    ? lead.etiqueta.join(", ")
                    : lead.etiqueta}
                </td>{" "}
                {/* Garantir que seja um array */}
                <td>{lead.dataInteracao}</td>
                <td>
                  <td>
                    <span
                      onDoubleClick={() => {
                        if (isAdmin) {
                          setSelectedLead(lead);
                          setEditedConsultora(lead.consultora);
                          setIsConsultoraModalOpen(true);
                        } else {
                          alert(
                            "Você não tem permissão para alterar o nome da carteira."
                          );
                        }
                      }}
                    >
                      {lead.consultora}
                    </span>
                  </td>
                </td>
                <td
                  onDoubleClick={() => {
                    if (isAdmin) {
                      setSelectedLead(lead);
                      setIsModalOpen(true); // Abre o modal
                    } else {
                      alert("Você não tem permissão para alterar o status.");
                    }
                  }}
                >
                  {lead.status || "Aberto"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Modal para alterar o status */}
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3>Alterar Status</h3>
              <select
                value={newStatus}
                onChange={(e) => setNewStatus(e.target.value)}
              >
                <option value="">Selecione um status</option>
                <option value="Perdeu Interesse">Perdeu Interesse</option>
                <option value="Em contato">Em contato</option>
                <option value="Comprou">Comprou</option>
                <option value="Não Responde">Não Responde</option>
                <option value="Hold">Hold</option>
              </select>
              <button onClick={updateLeadStatus}>Salvar</button>
              <button onClick={() => setIsModalOpen(false)}>Cancelar</button>
            </div>
          </div>
        )}
        {isNameModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3>Editar Nome</h3>
              <input
                type="text"
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                placeholder="Digite o novo nome"
                required
                autoFocus
              />
              <button onClick={updateLeadName}>Salvar</button>
              <button onClick={() => setIsNameModalOpen(false)}>
                Cancelar
              </button>
            </div>
          </div>
        )}
        {isConsultoraModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3>Editar Nome da Consultora</h3>
              <input
                type="text"
                value={editedConsultora}
                onChange={(e) => setEditedConsultora(e.target.value)}
              />
              <button onClick={updateConsultora}>Salvar</button>
              <button onClick={() => setIsConsultoraModalOpen(false)}>
                Cancelar
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GestaoLeads;
