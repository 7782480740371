// Unauthorized.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Unauthorized.css"; // Importa o CSS

const Unauthorized = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Volta para a página anterior
  };

  return (
    <div className="unauthorized-page">
      <h1>Acesso Negado</h1>
      <p>Você não tem permissão para acessar esta página.</p>
      <button onClick={handleGoBack}>Voltar</button>
    </div>
  );
};

export default Unauthorized;
