import React from "react";
//import './Footer.css';
import "./main.css"; // Estilo para o layout

function Footer() {
  return (
    <footer>
      <p>©2024 MaisLaser - Barra da Tijuca. Todos os direitos reservados.</p>
    </footer>
  );
}

export default Footer;
