// claimsManager.js
import { httpsCallable } from "firebase/functions";
import { functions } from "../config/firebase"; // Certifique-se de importar corretamente

export async function configureUserClaims() {
  try {
    const setupClaims = httpsCallable(functions, "setupClaims");
    const result = await setupClaims();
    console.log(result.data.message);
  } catch (error) {
    console.error("Erro ao configurar claims:", error);
  }
}
