import React, { useState } from "react";
import data from "../data/cleaned_tabelaPreco.json"; // Importa o JSON contendo os tratamentos
import "./main.css";
import domtoimage from "dom-to-image-more";

function CalculadoraOrcamentoConsultoras() {
  const linhaInicial = {
    tipo: "",
    sexo: "",
    procedimento: "",
    area: "",
    quantidade: "",
    sessao: 0,
    pacote: 0,
    descontoCustomizado: null,
  };
  const [linhas, setLinhas] = useState([linhaInicial]);
  const [customDiscount, setCustomDiscount] = useState(null);
  const [textoPronto, setTextoPronto] = useState("");
  const [selectedCells, setSelectedCells] = useState([]);
  const [selectedText, setSelectedText] = useState(""); // New state for text from cell selections

  const determinarParcelasDepilacao = (linhas) => {
    let parcelas = 10; // Padrão para áreas P

    linhas.forEach((linha) => {
      if (linha.tipo === "Depilação") {
        if (linha.area === "M") {
          parcelas = Math.max(parcelas, 12);
        } else if (linha.area === "G") {
          parcelas = Math.max(parcelas, 18);
        }
      }
    });

    return parcelas;
  };

  const handleTipoChange = (index, selectedTipo) => {
    setLinhas(
      linhas.map((linha, i) =>
        i === index
          ? {
              ...linha,
              tipo: selectedTipo,
              sexo: "",
              procedimento: "",
              area: "",
              sessao: 0,
              pacote: 0,
            }
          : linha
      )
    );
  };

  const handleSexoChange = (index, selectedSexo) => {
    setLinhas(
      linhas.map((linha, i) =>
        i === index
          ? {
              ...linha,
              sexo: selectedSexo,
              procedimento: "",
              sessao: 0,
              pacote: 0,
            }
          : linha
      )
    );
  };

  const handleProcedimentoChange = (index, selectedNome) => {
    const tratamentoSelecionado = data.find(
      (item) => item.Nome === selectedNome
    );
    setLinhas(
      linhas.map((linha, i) =>
        i === index
          ? {
              ...linha,
              procedimento: selectedNome,
              area: tratamentoSelecionado.Area || "",
              sessao: tratamentoSelecionado.Valor,
              pacote: tratamentoSelecionado.Valor * linha.quantidade,
            }
          : linha
      )
    );
    console.log(tratamentoSelecionado.Area);
  };

  const handleQuantidadeChange = (index, quantidade) => {
    setLinhas(
      linhas.map((linha, i) =>
        i === index
          ? {
              ...linha,
              quantidade,
              pacote: linha.sessao * quantidade,
            }
          : linha
      )
    );
  };

  const handleDescontoPersonalizadoChange = (value) => {
    setCustomDiscount(value ? parseFloat(value) : null);
  };

  const adicionarLinha = () => {
    setLinhas([...linhas, linhaInicial]);
  };

  const limparCalculadora = () => {
    setLinhas([linhaInicial]);
    setCustomDiscount(null);
    setSelectedCells([]); // Clear selected cells
    setSelectedText(""); // Clear the selected text
    setTextoPronto(""); // Clear the generated text
  };

  // Funções para calcular os totais conforme o tipo
  const calcularTotalPorTipo = (tipo) => {
    return linhas
      .filter((linha) => linha.tipo === tipo)
      .reduce((acc, linha) => acc + linha.pacote, 0);
  };

  const totalOmer = calcularTotalPorTipo("Ômer");
  const totalDepilacao = calcularTotalPorTipo("Depilação");
  const totalGeral = totalOmer + totalDepilacao;

  // Função para calcular as parcelas com desconto
  const calcularParcelasDesconto = (valor, totalDepilacao) => {
    const descontos =
      totalDepilacao > 0 ? [0.3, 0.27, 0.24, 0.2] : [0.95, 0.9, 0.85, 0.8];
    return descontos.map((desconto) => (valor * desconto).toFixed(2));
  };

  const calcularParcelasDescontoCustomizado = (valor) => {
    return customDiscount ? (valor * (1 - customDiscount)).toFixed(2) : null;
  };

  // Função para copiar a div "calculadora" como imagem

  const copiarCalculadora = async () => {
    const calculadoraDiv = document.querySelector(".calculadora");
    if (!calculadoraDiv) return;

    try {
      const blob = await domtoimage.toBlob(calculadoraDiv, {
        bgcolor: "#f0f8f8", // Cor de fundo opcional
      });

      await navigator.clipboard.write([
        new ClipboardItem({ "image/png": blob }),
      ]);

      alert("Imagem copiada para a área de transferência!");
    } catch (error) {
      console.error("Erro ao copiar imagem:", error);
      alert("Erro ao copiar a calculadora. Tente novamente.");
    }
  };
  React.useEffect(() => {
    const gerarTextoPronto = () => {
      const texto = linhas
        .filter((linha) => linha.procedimento && linha.quantidade > 0)
        .map((linha) => {
          const procedimento = linha.procedimento.includes("-")
            ? linha.procedimento.split("-")[1].split("(")[0].trim()
            : linha.procedimento;
          return `✨ ${linha.quantidade} Sessões de ${procedimento}`;
        })
        .join("\n");
      setTextoPronto(texto);
    };
    gerarTextoPronto();
  }, [linhas]);

  const handleAddToTextarea = (text, cellIdentifier) => {
    setSelectedCells((prevSelectedCells) => {
      // Check if the cell is already selected
      if (prevSelectedCells.includes(cellIdentifier)) {
        // Deselect the cell if it's already selected
        const updatedSelection = prevSelectedCells.filter(
          (id) => id !== cellIdentifier
        );

        // Update the selected text without the deselected cell text
        const updatedText = selectedText
          .split(" ou ")
          .filter((item) => item !== text)
          .join(" ou ");

        setSelectedText(updatedText);
        return updatedSelection;
      } else {
        // Add the cell if less than 2 cells are selected
        if (prevSelectedCells.length < 2) {
          const newSelection = [...prevSelectedCells, cellIdentifier];

          // Update the selected text in the textarea with "ou" separator
          const updatedText = [
            ...selectedText.split(" ou ").filter((line) => !!line),
            text,
          ].join(" ou ");
          setSelectedText(updatedText);

          return newSelection;
        } else {
          // Alert the user if they attempt to select more than 2 cells
          alert(
            "Você só pode selecionar até 2 opções. Desmarque uma para selecionar outra."
          );
          return prevSelectedCells;
        }
      }
    });
  };

  const handleCopy = () => {
    const textToCopy = `${textoPronto}\n\n${selectedText}`.trim();
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        alert("Conteúdo copiado!");
      })
      .catch((err) => {
        console.error("Erro ao copiar o conteúdo: ", err);
      });
  };

  const checkBackgroundColor = (element, color) => {
    return window.getComputedStyle(element).backgroundColor === color;
  };

  const handleCheckBoxChange = (index) => {
    setLinhas((prevLinhas) =>
      prevLinhas.map((linha, i) =>
        i === index && linha.tipo === "Ômer"
          ? {
              ...linha,
              pacote: linha.pacote !== 0 ? 0 : linha.quantidade * linha.sessao,
              quantidade: linha.pacote !== 0 ? 1 : linha.quantidade, // Ajusta quantidade para 1 se for cortesia
            }
          : linha
      )
    );
  };

  const maxQuantidadeOmer = linhas
    .filter((linha) => linha.tipo === "Ômer")
    .reduce((max, linha) => Math.max(max, linha.quantidade || 0), 0);

  const isCheckboxSelectedForOmer = linhas.some(
    (linha) => linha.tipo === "Ômer" && linha.pacote === 0
  );

  return (
    <section id="calculadora">
      <h2>Calculadora de Orçamentos</h2>
      <div className="button-container">
        <button onClick={adicionarLinha}>Adicionar Tratamento</button>
        <button onClick={limparCalculadora}>Limpar Calculadora</button>
        <button onClick={copiarCalculadora}>Copiar Calculadora</button>
      </div>
      <div className="calculadora">
        <div className="calculadora-left">
          <table>
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Sexo</th>
                <th>Procedimento</th>
                <th>Área</th>
                <th>Quantidade</th>
                <th>Sessão</th>
                <th>Pacote</th>
                <th>Cortesia</th>
              </tr>
            </thead>
            <tbody>
              {linhas.map((linha, index) => {
                // Calculate filtered procedures based on selected tipo and sexo
                const procedimentosFiltrados = data.filter(
                  (item) =>
                    item.Tipo === linha.tipo &&
                    (linha.tipo !== "Depilação" || item.Sexo === linha.sexo)
                );

                return (
                  <tr key={index}>
                    <td>
                      <select
                        value={linha.tipo}
                        onChange={(e) =>
                          handleTipoChange(index, e.target.value)
                        }
                      >
                        <option value="">Selecione o Tipo</option>
                        {[...new Set(data.map((item) => item.Tipo))].map(
                          (tipo) => (
                            <option key={tipo} value={tipo}>
                              {tipo}
                            </option>
                          )
                        )}
                      </select>
                    </td>
                    <td>
                      {linha.tipo === "Depilação" && (
                        <select
                          value={linha.sexo || ""}
                          onChange={(e) =>
                            handleSexoChange(index, e.target.value)
                          }
                        >
                          <option value="">Selecione o Sexo</option>
                          <option value="M">Masculino</option>
                          <option value="F">Feminino</option>
                        </select>
                      )}
                    </td>
                    <td>
                      <select
                        value={linha.procedimento}
                        onChange={(e) =>
                          handleProcedimentoChange(index, e.target.value)
                        }
                        disabled={
                          !linha.tipo ||
                          (linha.tipo === "Depilação" && !linha.sexo)
                        }
                      >
                        <option value="">Selecione o Procedimento</option>
                        {procedimentosFiltrados.map((item) => (
                          <option key={item.ID} value={item.Nome}>
                            {item.Nome}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>{linha.area}</td>
                    <td>
                      <input
                        type="number"
                        min="1"
                        max="10"
                        value={linha.quantidade}
                        onChange={(e) =>
                          handleQuantidadeChange(index, Number(e.target.value))
                        }
                      />
                    </td>
                    <td>{"R$ " + linha.sessao.toFixed(2)}</td>
                    <td>{"R$ " + linha.pacote.toFixed(2)}</td>
                    <td>
                      <input
                        type="checkbox"
                        onChange={() => handleCheckBoxChange(index)}
                        disabled={linha.tipo !== "Ômer"}
                        checked={linha.pacote === 0 && linha.tipo === "Ômer"} // Optional: to visually indicate if it's forced to zero
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Seção de Totais */}
          <div className="totais">
            {totalOmer > 0 && <p>Total Ômer: R$ {totalOmer.toFixed(2)}</p>}
            {totalDepilacao > 0 && (
              <p>Total Depilação: R$ {totalDepilacao.toFixed(2)}</p>
            )}
            {totalOmer > 0 && totalDepilacao > 0 && (
              <p>Total Geral: R$ {totalGeral.toFixed(2)}</p>
            )}
          </div>

          {/* Tabela de Parcelas com Desconto */}
          <div className="resultado">
            {totalDepilacao > 0 && totalOmer === 0 && (
              <h3>Calculadora de Descontos Depilação</h3>
            )}
            {totalOmer > 0 && totalDepilacao === 0 && (
              <h3>Calculadora de Descontos Ômer</h3>
            )}
            {totalDepilacao > 0 && totalOmer > 0 && (
              <h3>Calculadora de Descontos Depilação com Ômer</h3>
            )}
            <table className="descontos">
              <thead>
                <tr>
                  <th>Forma de Pagamento</th>
                  {(totalOmer > 0 || totalDepilacao > 0) &&
                    calcularParcelasDesconto(totalGeral, totalDepilacao).map(
                      (_, index) => (
                        <th key={index}>
                          {(
                            (totalDepilacao > 0
                              ? [0.7, 0.73, 0.76, 0.8][index]
                              : [0.05, 0.1, 0.15, 0.2][index]) * 100
                          ).toFixed(0)}
                          %
                        </th>
                      )
                    )}
                  <th>
                    <select
                      value={customDiscount || ""}
                      onChange={(e) =>
                        handleDescontoPersonalizadoChange(e.target.value)
                      }
                    >
                      <option value="">Selecione (%)</option>
                      {[...Array(90)].map((_, i) => (
                        <option key={i + 1} value={(i + 1) / 100}>
                          {i + 1}%
                        </option>
                      ))}
                    </select>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Pix / Débito</td>
                  {calcularParcelasDesconto(totalGeral, totalDepilacao).map(
                    (valor, index) => (
                      <td
                        key={index}
                        onClick={(e) => {
                          // Check if the background color is the specified restricted red color
                          if (
                            checkBackgroundColor(
                              e.currentTarget,
                              "rgba(255, 0, 0, 0.1)"
                            )
                          ) {
                            alert(
                              "Atenção: essa opção só pode ser selecionada com autorização prévia. Falar com Felipe ou Clara"
                            );
                          } else {
                            handleAddToTextarea(
                              `Pix: R$ ${valor}`,
                              `Pix-${index}`
                            );
                          }
                        }}
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            totalGeral > 0
                              ? "rgba(0, 255, 0, 0.1)"
                              : "transparent",
                        }}
                        className={
                          selectedCells.includes(`Pix-${index}`)
                            ? "selected"
                            : ""
                        }
                      >
                        R$ {valor}
                      </td>
                    )
                  )}
                  <td
                    onClick={(e) => {
                      // Check if the cell has a restricted background color and display an alert if so
                      if (
                        (totalDepilacao > 0 && customDiscount > 0.8) ||
                        (totalOmer > 0 && customDiscount > 0.2)
                      ) {
                        alert(
                          "Atenção: essa opção só pode ser selecionada com autorização prévia. Falar com Felipe ou Clara"
                        );
                        handleAddToTextarea(
                          `Pix: R$ ${calcularParcelasDescontoCustomizado(
                            totalGeral
                          )}`,
                          "Pix-custom"
                        );
                      } else if (customDiscount !== null) {
                        handleAddToTextarea(
                          `Pix: R$ ${calcularParcelasDescontoCustomizado(
                            totalGeral
                          )}`,
                          "Pix-custom"
                        );
                      }
                    }}
                    style={{
                      backgroundColor:
                        (totalDepilacao > 0 && customDiscount > 0.8) ||
                        (totalOmer > 0 && customDiscount > 0.2)
                          ? "rgba(255, 0, 0, 0.1)" // Light red for restricted selections
                          : customDiscount > 0 && customDiscount <= 0.8
                          ? "rgba(0, 255, 0, 0.1)" // Light green for allowed selections
                          : "transparent", // Default background
                      cursor: customDiscount !== null ? "pointer" : "default",
                    }}
                    className={
                      selectedCells.includes("Pix-custom") ? "selected" : ""
                    }
                  >
                    {customDiscount !== null
                      ? `R$ ${calcularParcelasDescontoCustomizado(totalGeral)}`
                      : ""}
                  </td>
                </tr>
                {((totalDepilacao > 0 &&
                  linhas.some((linha) => linha.quantidade === 10)) ||
                  totalOmer > 0) && (
                  <tr>
                    <td>
                      {totalOmer > 0 && totalDepilacao === 0
                        ? `Recorrente (${
                            maxQuantidadeOmer +
                            (isCheckboxSelectedForOmer ? 1 : 0)
                          }x)`
                        : "Boleto (10x)"}
                    </td>
                    {calcularParcelasDesconto(totalGeral, totalDepilacao).map(
                      (valor, index) => (
                        <td
                          key={index}
                          onClick={(e) => {
                            // Check if the background color is the specified restricted red color
                            if (
                              checkBackgroundColor(
                                e.currentTarget,
                                "rgba(255, 0, 0, 0.1)"
                              )
                            ) {
                              alert(
                                "Atenção: essa opção só pode ser selecionada com autorização prévia. Falar com Felipe ou Clara"
                              );
                              if (
                                totalOmer > 0 &&
                                totalDepilacao === 0 &&
                                isCheckboxSelectedForOmer
                              ) {
                                handleAddToTextarea(
                                  `${maxQuantidadeOmer + 1}x R$ ${(
                                    valor /
                                    (maxQuantidadeOmer + 1)
                                  ).toFixed(2)} (${
                                    totalOmer > 0 && totalDepilacao === 0
                                      ? "Recorrente"
                                      : "Boleto"
                                  })`,
                                  `${
                                    totalOmer > 0 && totalDepilacao === 0
                                      ? "Recorrente"
                                      : "Boleto"
                                  }-10x-${index}`
                                );
                              } else if (
                                totalOmer > 0 &&
                                totalDepilacao === 0
                              ) {
                                handleAddToTextarea(
                                  `${maxQuantidadeOmer}x R$ ${(
                                    valor / maxQuantidadeOmer
                                  ).toFixed(2)} (${
                                    totalOmer > 0 && totalDepilacao === 0
                                      ? "Recorrente"
                                      : "Boleto"
                                  })`,
                                  `${
                                    totalOmer > 0 && totalDepilacao === 0
                                      ? "Recorrente"
                                      : "Boleto"
                                  }-10x-${index}`
                                );
                              } else {
                                handleAddToTextarea(
                                  `10x R$ ${(valor / 10).toFixed(2)} (${
                                    totalOmer > 0 && totalDepilacao === 0
                                      ? "Recorrente"
                                      : "Boleto"
                                  })`,
                                  `${
                                    totalOmer > 0 && totalDepilacao === 0
                                      ? "Recorrente"
                                      : "Boleto"
                                  }-10x-${index}`
                                );
                              }
                            } else {
                              if (
                                totalOmer > 0 &&
                                totalDepilacao === 0 &&
                                isCheckboxSelectedForOmer
                              ) {
                                handleAddToTextarea(
                                  `${maxQuantidadeOmer + 1}x R$ ${(
                                    valor /
                                    (maxQuantidadeOmer + 1)
                                  ).toFixed(2)} (${
                                    totalOmer > 0 && totalDepilacao === 0
                                      ? "Recorrente"
                                      : "Boleto"
                                  })`,
                                  `${
                                    totalOmer > 0 && totalDepilacao === 0
                                      ? "Recorrente"
                                      : "Boleto"
                                  }-10x-${index}`
                                );
                              } else if (
                                totalOmer > 0 &&
                                totalDepilacao === 0
                              ) {
                                handleAddToTextarea(
                                  `${maxQuantidadeOmer}x R$ ${(
                                    valor / maxQuantidadeOmer
                                  ).toFixed(2)} (${
                                    totalOmer > 0 && totalDepilacao === 0
                                      ? "Recorrente"
                                      : "Boleto"
                                  })`,
                                  `${
                                    totalOmer > 0 && totalDepilacao === 0
                                      ? "Recorrente"
                                      : "Boleto"
                                  }-10x-${index}`
                                );
                              } else {
                                handleAddToTextarea(
                                  `10x R$ ${(valor / 10).toFixed(2)} (${
                                    totalOmer > 0 && totalDepilacao === 0
                                      ? "Recorrente"
                                      : "Boleto"
                                  })`,
                                  `${
                                    totalOmer > 0 && totalDepilacao === 0
                                      ? "Recorrente"
                                      : "Boleto"
                                  }-10x-${index}`
                                );
                              }
                            }
                          }}
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              totalOmer > 0
                                ? "rgba(0, 255, 0, 0.1)" // Light green for Ômer discounts (5% to 20%)
                                : totalDepilacao > 0 && index === 0
                                ? "rgba(0, 255, 0, 0.1)" // Light green for specific Depilação cell (index 0)
                                : totalDepilacao > 0 &&
                                  (index === 1 || index === 2 || index === 3)
                                ? "rgba(255, 0, 0, 0.1)" // Light red for specific Depilação cells (index 1, 2, 3)
                                : "transparent", // Default background if none of the conditions match
                          }}
                          className={
                            selectedCells.includes(
                              `${
                                totalOmer > 0 && totalDepilacao === 0
                                  ? "Recorrente"
                                  : "Boleto"
                              }-10x-${index}`
                            )
                              ? "selected"
                              : ""
                          }
                        >
                          R${" "}
                          {totalOmer > 0 &&
                          totalDepilacao === 0 &&
                          isCheckboxSelectedForOmer
                            ? (valor / (maxQuantidadeOmer + 1)).toFixed(2)
                            : totalOmer > 0 && totalDepilacao === 0
                            ? (valor / maxQuantidadeOmer).toFixed(2)
                            : (valor / 10).toFixed(2)}
                        </td>
                      )
                    )}
                    <td
                      onClick={(e) => {
                        // Check if the cell has a restricted background color and display an alert if so
                        if (
                          (totalDepilacao > 0 && customDiscount > 0.7) ||
                          (totalOmer > 0 && customDiscount > 0.2)
                        ) {
                          alert(
                            "Atenção: essa opção só pode ser selecionada com autorização prévia. Falar com Felipe ou Clara"
                          );
                          if (
                            totalOmer > 0 &&
                            totalDepilacao === 0 &&
                            isCheckboxSelectedForOmer
                          ) {
                            handleAddToTextarea(
                              `${maxQuantidadeOmer + 1}x R$ ${(
                                calcularParcelasDescontoCustomizado(
                                  totalGeral
                                ) /
                                (maxQuantidadeOmer + 1)
                              ).toFixed(2)} (${
                                totalOmer > 0 && totalDepilacao === 0
                                  ? "Recorrente"
                                  : "Boleto"
                              })`,
                              `${
                                totalOmer > 0 && totalDepilacao === 0
                                  ? "Recorrente"
                                  : "Boleto"
                              }-10x-custom`
                            );
                          } else if (totalOmer > 0 && totalDepilacao === 0) {
                            handleAddToTextarea(
                              `${maxQuantidadeOmer}x R$ ${(
                                calcularParcelasDescontoCustomizado(
                                  totalGeral
                                ) / maxQuantidadeOmer
                              ).toFixed(2)} (${
                                totalOmer > 0 && totalDepilacao === 0
                                  ? "Recorrente"
                                  : "Boleto"
                              })`,
                              `${
                                totalOmer > 0 && totalDepilacao === 0
                                  ? "Recorrente"
                                  : "Boleto"
                              }-10x-custom`
                            );
                          } else {
                            handleAddToTextarea(
                              `10x R$ ${(
                                calcularParcelasDescontoCustomizado(
                                  totalGeral
                                ) / 10
                              ).toFixed(2)} (${
                                totalOmer > 0 && totalDepilacao === 0
                                  ? "Recorrente"
                                  : "Boleto"
                              })`,
                              `${
                                totalOmer > 0 && totalDepilacao === 0
                                  ? "Recorrente"
                                  : "Boleto"
                              }-10x-custom`
                            );
                          }
                        } else if (customDiscount !== null) {
                          if (
                            totalOmer > 0 &&
                            totalDepilacao === 0 &&
                            isCheckboxSelectedForOmer
                          ) {
                            handleAddToTextarea(
                              `${maxQuantidadeOmer + 1}x R$ ${(
                                calcularParcelasDescontoCustomizado(
                                  totalGeral
                                ) /
                                (maxQuantidadeOmer + 1)
                              ).toFixed(2)} (${
                                totalOmer > 0 && totalDepilacao === 0
                                  ? "Recorrente"
                                  : "Boleto"
                              })`,
                              `${
                                totalOmer > 0 && totalDepilacao === 0
                                  ? "Recorrente"
                                  : "Boleto"
                              }-10x-custom`
                            );
                          } else if (totalOmer > 0 && totalDepilacao === 0) {
                            handleAddToTextarea(
                              `${maxQuantidadeOmer}x R$ ${(
                                calcularParcelasDescontoCustomizado(
                                  totalGeral
                                ) / maxQuantidadeOmer
                              ).toFixed(2)} (${
                                totalOmer > 0 && totalDepilacao === 0
                                  ? "Recorrente"
                                  : "Boleto"
                              })`,
                              `${
                                totalOmer > 0 && totalDepilacao === 0
                                  ? "Recorrente"
                                  : "Boleto"
                              }-10x-custom`
                            );
                          } else {
                            handleAddToTextarea(
                              `10x R$ ${(
                                calcularParcelasDescontoCustomizado(
                                  totalGeral
                                ) / 10
                              ).toFixed(2)} (${
                                totalOmer > 0 && totalDepilacao === 0
                                  ? "Recorrente"
                                  : "Boleto"
                              } )`,
                              `${
                                totalOmer > 0 && totalDepilacao === 0
                                  ? "Recorrente"
                                  : "Boleto"
                              }-10x-custom`
                            );
                          }
                        }
                      }}
                      style={{
                        backgroundColor:
                          (totalDepilacao > 0 && customDiscount > 0.7) ||
                          (totalOmer > 0 && customDiscount > 0.2)
                            ? "rgba(255, 0, 0, 0.1)" // Light red for restricted selections
                            : customDiscount > 0 && customDiscount <= 0.7
                            ? "rgba(0, 255, 0, 0.1)" // Light green for allowed selections
                            : "transparent", // Default background
                        cursor: customDiscount !== null ? "pointer" : "default",
                      }}
                      className={
                        selectedCells.includes(
                          `${
                            totalOmer > 0 && totalDepilacao === 0
                              ? "Recorrente"
                              : "Boleto"
                          }-10x-custom`
                        )
                          ? "selected"
                          : ""
                      }
                    >
                      {customDiscount !== null &&
                      totalOmer > 0 &&
                      totalDepilacao === 0 &&
                      isCheckboxSelectedForOmer
                        ? `R$ ${(
                            calcularParcelasDescontoCustomizado(totalGeral) /
                            (maxQuantidadeOmer + 1)
                          ).toFixed(2)}`
                        : customDiscount !== null &&
                          totalOmer > 0 &&
                          totalDepilacao === 0
                        ? `R$ ${(
                            calcularParcelasDescontoCustomizado(totalGeral) /
                            maxQuantidadeOmer
                          ).toFixed(2)}`
                        : customDiscount !== null
                        ? `R$ ${(
                            calcularParcelasDescontoCustomizado(totalGeral) / 10
                          ).toFixed(2)}`
                        : ""}
                    </td>
                  </tr>
                )}
                {((totalDepilacao > 0 &&
                  linhas.some((linha) => linha.quantidade === 10)) ||
                  totalOmer > 0) && (
                  <tr>
                    <td>
                      Crédito (
                      {totalOmer > 0 && totalDepilacao === 0
                        ? `${Math.min(
                            Math.min(2 * maxQuantidadeOmer, 12) +
                              (isCheckboxSelectedForOmer ? 1 : 0),
                            12
                          )}x`
                        : "12x"}
                      )
                    </td>
                    {calcularParcelasDesconto(totalGeral, totalDepilacao).map(
                      (valor, index) => (
                        <td
                          key={index}
                          onClick={(e) => {
                            // Check if the background color is the specified restricted red color
                            if (
                              checkBackgroundColor(
                                e.currentTarget,
                                "rgba(255, 0, 0, 0.1)"
                              )
                            ) {
                              alert(
                                "Atenção: essa opção só pode ser selecionada com autorização prévia. Falar com Felipe ou Clara"
                              );
                              if (
                                totalOmer > 0 &&
                                totalDepilacao === 0 &&
                                isCheckboxSelectedForOmer
                              ) {
                                handleAddToTextarea(
                                  `${Math.min(
                                    2 * maxQuantidadeOmer + 1,
                                    12
                                  )}x R$ ${(
                                    valor /
                                    Math.min(2 * maxQuantidadeOmer + 1, 12)
                                  ).toFixed(2)} (Crédito s/ juros)`,
                                  `Credito-${index}`
                                );
                              } else if (
                                totalOmer > 0 &&
                                totalDepilacao === 0
                              ) {
                                handleAddToTextarea(
                                  `${Math.min(
                                    2 * maxQuantidadeOmer,
                                    12
                                  )}x R$ ${(
                                    valor / Math.min(2 * maxQuantidadeOmer, 12)
                                  ).toFixed(2)} (Crédito s/ juros)`,
                                  `Credito-${index}`
                                );
                              } else {
                                handleAddToTextarea(
                                  `12x R$ ${(valor / 12).toFixed(
                                    2
                                  )} (Crédito s/ juros)`,
                                  `Credito-${index}`
                                );
                              }
                            } else {
                              if (
                                totalOmer > 0 &&
                                totalDepilacao === 0 &&
                                isCheckboxSelectedForOmer
                              ) {
                                handleAddToTextarea(
                                  `${Math.min(
                                    2 * maxQuantidadeOmer + 1,
                                    12
                                  )}x R$ ${(
                                    valor /
                                    Math.min(2 * maxQuantidadeOmer + 1, 12)
                                  ).toFixed(2)} (Crédito s/ juros)`,
                                  `Credito-${index}`
                                );
                              } else if (
                                totalOmer > 0 &&
                                totalDepilacao === 0
                              ) {
                                handleAddToTextarea(
                                  `${Math.min(
                                    2 * maxQuantidadeOmer,
                                    12
                                  )}x R$ ${(
                                    valor / Math.min(2 * maxQuantidadeOmer, 12)
                                  ).toFixed(2)} (Crédito s/ juros)`,
                                  `Credito-${index}`
                                );
                              } else {
                                handleAddToTextarea(
                                  `12x R$ ${(valor / 12).toFixed(
                                    2
                                  )} (Crédito s/ juros)`,
                                  `Credito-${index}`
                                );
                              }
                            }
                          }}
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              totalOmer > 0
                                ? "rgba(0, 255, 0, 0.1)" // Light green for Ômer discounts (5% to 20%)
                                : totalDepilacao > 0 &&
                                  (index === 0 || index === 1 || index === 2)
                                ? "rgba(0, 255, 0, 0.1)" // Light green for specific Depilação cells (index 0, 1, 2)
                                : totalDepilacao > 0 && index === 3
                                ? "rgba(255, 0, 0, 0.1)" // Light red for specific Depilação cell (index 3)
                                : "transparent", // Default background if none of the conditions match
                          }}
                          className={
                            selectedCells.includes(`Credito-${index}`)
                              ? "selected"
                              : ""
                          }
                        >
                          R${" "}
                          {totalOmer > 0 &&
                          totalDepilacao === 0 &&
                          isCheckboxSelectedForOmer
                            ? (
                                valor / Math.min(2 * maxQuantidadeOmer + 1, 12)
                              ).toFixed(2)
                            : totalOmer > 0 && totalDepilacao === 0
                            ? (
                                valor / Math.min(2 * maxQuantidadeOmer, 12)
                              ).toFixed(2)
                            : (valor / 12).toFixed(2)}
                        </td>
                      )
                    )}
                    <td
                      onClick={(e) => {
                        // Check if the cell has a restricted background color and display an alert if so
                        if (
                          (totalDepilacao > 0 && customDiscount > 0.76) ||
                          (totalOmer > 0 && customDiscount > 0.2)
                        ) {
                          alert(
                            "Atenção: essa opção só pode ser selecionada com autorização prévia. Falar com Felipe ou Clara"
                          );
                          if (
                            totalOmer > 0 &&
                            totalDepilacao === 0 &&
                            isCheckboxSelectedForOmer
                          ) {
                            handleAddToTextarea(
                              `${Math.min(
                                2 * maxQuantidadeOmer + 1,
                                12
                              )}x R$ ${(
                                calcularParcelasDescontoCustomizado(
                                  totalGeral
                                ) / Math.min(2 * maxQuantidadeOmer + 1, 12)
                              ).toFixed(2)} (Crédito s/ juros)`,
                              "Credito-custom"
                            );
                          } else if (totalOmer > 0 && totalDepilacao === 0) {
                            handleAddToTextarea(
                              `${Math.min(2 * maxQuantidadeOmer, 12)}x R$ ${(
                                calcularParcelasDescontoCustomizado(
                                  totalGeral
                                ) / Math.min(2 * maxQuantidadeOmer, 12)
                              ).toFixed(2)} (Crédito s/ juros)`,
                              "Credito-custom"
                            );
                          } else {
                            handleAddToTextarea(
                              `12x R$ ${(
                                calcularParcelasDescontoCustomizado(
                                  totalGeral
                                ) / 12
                              ).toFixed(2)} (Crédito s/ juros)`,
                              "Credito-custom"
                            );
                          }
                        } else if (customDiscount !== null) {
                          if (
                            totalOmer > 0 &&
                            totalDepilacao === 0 &&
                            isCheckboxSelectedForOmer
                          ) {
                            handleAddToTextarea(
                              `${Math.min(
                                2 * maxQuantidadeOmer + 1,
                                12
                              )}x R$ ${(
                                calcularParcelasDescontoCustomizado(
                                  totalGeral
                                ) / Math.min(2 * maxQuantidadeOmer + 1, 12)
                              ).toFixed(2)} (Crédito s/ juros)`,
                              "Credito-custom"
                            );
                          } else if (totalOmer > 0 && totalDepilacao === 0) {
                            handleAddToTextarea(
                              `${Math.min(2 * maxQuantidadeOmer, 12)}x R$ ${(
                                calcularParcelasDescontoCustomizado(
                                  totalGeral
                                ) / Math.min(2 * maxQuantidadeOmer, 12)
                              ).toFixed(2)} (Crédito s/ juros)`,
                              "Credito-custom"
                            );
                          } else {
                            handleAddToTextarea(
                              `12x R$ ${(
                                calcularParcelasDescontoCustomizado(
                                  totalGeral
                                ) / 12
                              ).toFixed(2)} (Crédito s/ juros)`,
                              "Credito-custom"
                            );
                          }
                        }
                      }}
                      style={{
                        backgroundColor:
                          (totalDepilacao > 0 && customDiscount > 0.76) ||
                          (totalOmer > 0 && customDiscount > 0.2)
                            ? "rgba(255, 0, 0, 0.1)" // Light red for restricted selections
                            : customDiscount > 0 && customDiscount <= 0.76
                            ? "rgba(0, 255, 0, 0.1)" // Light green for allowed selections
                            : "transparent", // Default background
                        cursor: customDiscount !== null ? "pointer" : "default",
                      }}
                      className={
                        selectedCells.includes("Credito-custom")
                          ? "selected"
                          : ""
                      }
                    >
                      {customDiscount !== null &&
                      totalOmer > 0 &&
                      totalDepilacao === 0 &&
                      isCheckboxSelectedForOmer
                        ? `R$ ${(
                            calcularParcelasDescontoCustomizado(totalGeral) /
                            Math.min(2 * maxQuantidadeOmer + 1, 12)
                          ).toFixed(2)}`
                        : customDiscount !== null &&
                          totalOmer > 0 &&
                          totalDepilacao === 0
                        ? `R$ ${(
                            calcularParcelasDescontoCustomizado(totalGeral) /
                            Math.min(2 * maxQuantidadeOmer, 12)
                          ).toFixed(2)}`
                        : customDiscount !== null
                        ? `R$ ${(
                            calcularParcelasDescontoCustomizado(totalGeral) / 12
                          ).toFixed(2)}`
                        : ""}
                    </td>
                  </tr>
                )}
                {totalDepilacao > 0 &&
                  linhas.some((linha) => linha.quantidade === 10) && (
                    <tr>
                      <td>
                        Recorrente (
                        {totalDepilacao > 0
                          ? `${determinarParcelasDepilacao(linhas)}x`
                          : "12x"}
                        )
                      </td>
                      {calcularParcelasDesconto(totalGeral, totalDepilacao).map(
                        (valor, index) => (
                          <td
                            key={index}
                            onClick={(e) => {
                              // Check if the background color is the restricted red color
                              if (
                                checkBackgroundColor(
                                  e.currentTarget,
                                  "rgba(255, 0, 0, 0.1)"
                                )
                              ) {
                                alert(
                                  "Atenção: essa opção só pode ser selecionada com autorização prévia. Falar com Felipe ou Clara"
                                );
                                handleAddToTextarea(
                                  `${
                                    totalDepilacao > 0
                                      ? determinarParcelasDepilacao(linhas)
                                      : 12
                                  }x R$ ${(
                                    valor /
                                    (totalDepilacao > 0
                                      ? determinarParcelasDepilacao(linhas)
                                      : 12)
                                  ).toFixed(2)} (Recorrente)`,
                                  `Recorrente-${
                                    totalDepilacao > 0
                                      ? determinarParcelasDepilacao(linhas)
                                      : 12
                                  }x-${index}`
                                );
                              } else {
                                handleAddToTextarea(
                                  `${
                                    totalDepilacao > 0
                                      ? determinarParcelasDepilacao(linhas)
                                      : 12
                                  }x R$ ${(
                                    valor /
                                    (totalDepilacao > 0
                                      ? determinarParcelasDepilacao(linhas)
                                      : 12)
                                  ).toFixed(2)} (Recorrente)`,
                                  `Recorrente-${
                                    totalDepilacao > 0
                                      ? determinarParcelasDepilacao(linhas)
                                      : 12
                                  }x-${index}`
                                );
                              }
                            }}
                            style={{
                              backgroundColor:
                                totalGeral > 0 && index === 0
                                  ? "rgba(0, 255, 0, 0.1)"
                                  : (totalGeral > 0 && index === 1) ||
                                    (totalGeral > 0 && index === 2) ||
                                    (totalGeral > 0 && index === 3)
                                  ? "rgba(255, 0, 0, 0.1)"
                                  : "transparent",
                              cursor: "pointer",
                            }}
                            className={
                              selectedCells.includes(
                                `Recorrente-${
                                  totalDepilacao > 0
                                    ? determinarParcelasDepilacao(linhas)
                                    : 12
                                }x-${index}`
                              )
                                ? "selected"
                                : ""
                            }
                          >
                            R${" "}
                            {(
                              valor /
                              (totalDepilacao > 0
                                ? determinarParcelasDepilacao(linhas)
                                : 12)
                            ).toFixed(2)}
                          </td>
                        )
                      )}
                      <td
                        onClick={(e) => {
                          // Check if customDiscount is applied and greater than 0
                          if (customDiscount > 0.7) {
                            alert(
                              "Atenção: essa opção só pode ser selecionada com autorização prévia. Falar com Felipe ou Clara"
                            );
                            handleAddToTextarea(
                              `${
                                totalDepilacao > 0
                                  ? determinarParcelasDepilacao(linhas)
                                  : 12
                              }x R$ ${(
                                calcularParcelasDescontoCustomizado(
                                  totalGeral
                                ) /
                                (totalDepilacao > 0
                                  ? determinarParcelasDepilacao(linhas)
                                  : 12)
                              ).toFixed(2)} (Recorrente)`,
                              `Recorrente-${
                                totalDepilacao > 0
                                  ? determinarParcelasDepilacao(linhas)
                                  : 12
                              }x-custom`
                            );
                          } else if (customDiscount !== null) {
                            handleAddToTextarea(
                              `${
                                totalDepilacao > 0
                                  ? determinarParcelasDepilacao(linhas)
                                  : 12
                              }x R$ ${(
                                calcularParcelasDescontoCustomizado(
                                  totalGeral
                                ) /
                                (totalDepilacao > 0
                                  ? determinarParcelasDepilacao(linhas)
                                  : 12)
                              ).toFixed(2)} (Recorrente)`,
                              `Recorrente-${
                                totalDepilacao > 0
                                  ? determinarParcelasDepilacao(linhas)
                                  : 12
                              }x-custom`
                            );
                          }
                        }}
                        style={{
                          backgroundColor:
                            customDiscount > 0.7
                              ? "rgba(255, 0, 0, 0.1)"
                              : customDiscount > 0
                              ? "rgba(0, 255, 0, 0.1)"
                              : "transparent",
                          cursor:
                            customDiscount !== null ? "pointer" : "default",
                        }}
                        className={
                          selectedCells.includes(
                            `Recorrente-${
                              totalDepilacao > 0
                                ? determinarParcelasDepilacao(linhas)
                                : 12
                            }x-custom`
                          )
                            ? "selected"
                            : ""
                        }
                      >
                        {customDiscount !== null
                          ? `R$ ${(
                              calcularParcelasDescontoCustomizado(totalGeral) /
                              (totalDepilacao > 0
                                ? determinarParcelasDepilacao(linhas)
                                : 12)
                            ).toFixed(2)}`
                          : ""}
                      </td>
                    </tr>
                  )}
                {totalDepilacao > 0 &&
                  linhas.every((linha) => linha.quantidade < 10) && (
                    <tr>
                      <td>Crédito ({linhas[0]?.quantidade || "0"}x)</td>
                      {calcularParcelasDesconto(totalGeral, totalDepilacao).map(
                        (valor, index) => (
                          <td
                            key={index}
                            onClick={(e) => {
                              // Check if the background color is the specified restricted red color
                              if (
                                checkBackgroundColor(
                                  e.currentTarget,
                                  "rgba(255, 0, 0, 0.1)"
                                )
                              ) {
                                alert(
                                  "Atenção: essa opção só pode ser selecionada com autorização prévia. Falar com Felipe ou Clara"
                                );
                                if (
                                  totalOmer > 0 &&
                                  totalDepilacao === 0 &&
                                  isCheckboxSelectedForOmer
                                ) {
                                  handleAddToTextarea(
                                    `${linhas[0]?.quantidade || "0"}x R$ ${(
                                      valor / (linhas[0]?.quantidade || 1)
                                    ).toFixed(2)} (Crédito s/ juros)`,
                                    `Credito-${index}`
                                  );
                                } else if (
                                  totalOmer > 0 &&
                                  totalDepilacao === 0
                                ) {
                                  handleAddToTextarea(
                                    `${linhas[0]?.quantidade || "0"}x R$ ${(
                                      valor / (linhas[0]?.quantidade || 1)
                                    ).toFixed(2)} (Crédito s/ juros)`,
                                    `Credito-${index}`
                                  );
                                } else {
                                  handleAddToTextarea(
                                    `${linhas[0]?.quantidade || "0"}x R$ ${(
                                      valor / (linhas[0]?.quantidade || 1)
                                    ).toFixed(2)} (Crédito s/ juros)`,
                                    `Credito-${index}`
                                  );
                                }
                              } else {
                                if (
                                  totalOmer > 0 &&
                                  totalDepilacao === 0 &&
                                  isCheckboxSelectedForOmer
                                ) {
                                  handleAddToTextarea(
                                    `${linhas[0]?.quantidade || "0"}x R$ ${(
                                      valor / (linhas[0]?.quantidade || 1)
                                    ).toFixed(2)} (Crédito s/ juros)`,
                                    `Credito-${index}`
                                  );
                                } else if (
                                  totalOmer > 0 &&
                                  totalDepilacao === 0
                                ) {
                                  handleAddToTextarea(
                                    `${linhas[0]?.quantidade || "0"}x R$ ${(
                                      valor / (linhas[0]?.quantidade || 1)
                                    ).toFixed(2)} (Crédito s/ juros)`,
                                    `Credito-${index}`
                                  );
                                } else {
                                  handleAddToTextarea(
                                    `${linhas[0]?.quantidade || "0"}x R$ ${(
                                      valor / (linhas[0]?.quantidade || 1)
                                    ).toFixed(2)} (Crédito s/ juros)`,
                                    `Credito-${index}`
                                  );
                                }
                              }
                            }}
                            style={{
                              cursor: "pointer",
                              backgroundColor:
                                (totalDepilacao > 0 &&
                                  linhas[0]?.quantidade === 1 &&
                                  (index === 0 ||
                                    index === 1 ||
                                    index === 2)) ||
                                (totalDepilacao > 0 &&
                                  linhas[0]?.quantidade > 1 &&
                                  (index === 0 || index === 1 || index === 2))
                                  ? "rgba(0, 255, 0, 0.1)" // Light green for specific Depilação cells (index 0, 1, 2)
                                  : totalDepilacao > 0 && index === 3
                                  ? "rgba(255, 0, 0, 0.1)" // Light red for specific Depilação cell (index 3)
                                  : "transparent", // Default background if none of the conditions match
                            }}
                            className={
                              selectedCells.includes(`Credito-${index}`)
                                ? "selected"
                                : ""
                            }
                          >
                            R${" "}
                            {(valor / (linhas[0]?.quantidade || 1)).toFixed(2)}
                          </td>
                        )
                      )}
                      <td
                        onClick={(e) => {
                          // Check if the cell has a restricted background color and display an alert if so
                          if (totalDepilacao > 0 && customDiscount > 0.76) {
                            alert(
                              "Atenção: essa opção só pode ser selecionada com autorização prévia. Falar com Felipe ou Clara"
                            );
                            if (
                              totalOmer > 0 &&
                              totalDepilacao === 0 &&
                              isCheckboxSelectedForOmer
                            ) {
                              handleAddToTextarea(
                                `$${linhas[0]?.quantidade || "0"}x R$ ${(
                                  calcularParcelasDescontoCustomizado(
                                    totalGeral
                                  ) / (linhas[0]?.quantidade || 1)
                                ).toFixed(2)} (Crédito s/ juros)`,
                                "Credito-custom"
                              );
                            } else if (totalOmer > 0 && totalDepilacao === 0) {
                              handleAddToTextarea(
                                `${linhas[0]?.quantidade || "0"}x R$ ${(
                                  calcularParcelasDescontoCustomizado(
                                    totalGeral
                                  ) / (linhas[0]?.quantidade || 1)
                                ).toFixed(2)} (Crédito s/ juros)`,
                                "Credito-custom"
                              );
                            } else {
                              handleAddToTextarea(
                                `${linhas[0]?.quantidade || "0"}x R$ ${(
                                  calcularParcelasDescontoCustomizado(
                                    totalGeral
                                  ) / (linhas[0]?.quantidade || 1)
                                ).toFixed(2)} (Crédito s/ juros)`,
                                "Credito-custom"
                              );
                            }
                          } else if (customDiscount !== null) {
                            if (
                              totalOmer > 0 &&
                              totalDepilacao === 0 &&
                              isCheckboxSelectedForOmer
                            ) {
                              handleAddToTextarea(
                                `${linhas[0]?.quantidade || "0"}x R$ ${(
                                  calcularParcelasDescontoCustomizado(
                                    totalGeral
                                  ) / Math.min(2 * maxQuantidadeOmer + 1, 12)
                                ).toFixed(2)} (Crédito s/ juros)`,
                                "Credito-custom"
                              );
                            } else if (totalOmer > 0 && totalDepilacao === 0) {
                              handleAddToTextarea(
                                `${linhas[0]?.quantidade || "0"}x R$ ${(
                                  calcularParcelasDescontoCustomizado(
                                    totalGeral
                                  ) / (linhas[0]?.quantidade || 1)
                                ).toFixed(2)} (Crédito s/ juros)`,
                                "Credito-custom"
                              );
                            } else {
                              handleAddToTextarea(
                                `${linhas[0]?.quantidade || "0"}x R$ ${(
                                  calcularParcelasDescontoCustomizado(
                                    totalGeral
                                  ) / (linhas[0]?.quantidade || 1)
                                ).toFixed(2)} (Crédito s/ juros)`,
                                "Credito-custom"
                              );
                            }
                          }
                        }}
                        style={{
                          backgroundColor:
                            totalDepilacao > 0 &&
                            customDiscount > 0.76 &&
                            linhas[0]?.quantidade === 1
                              ? "rgba(255, 0, 0, 0.1)" // Light red for restricted selections
                              : totalDepilacao > 0 &&
                                customDiscount <= 0.76 &&
                                customDiscount > 0
                              ? "rgba(0, 255, 0, 0.1)" // Light green for allowed selections
                              : "transparent", // Default background
                          cursor:
                            customDiscount !== null ? "pointer" : "default",
                        }}
                        className={
                          selectedCells.includes("Credito-custom")
                            ? "selected"
                            : ""
                        }
                      >
                        {customDiscount > 0 &&
                          `R$${" "}
                      ${(
                        calcularParcelasDescontoCustomizado(totalGeral) /
                        (linhas[0]?.quantidade || 1)
                      ).toFixed(2)}`}
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
          <div></div>
        </div>
        <div
          className="calculadora-right"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div style={{ marginBottom: "15px", textAlign: "justify" }}>
            <p>
              1. Deve ser incluído todos os procedimentos que estão sendo
              ofertados ao cliente.
            </p>
            <p>
              2. Ômer: Deve ser selecionada a caixa "cortesia" quando assim for
              ofertado ao cliente.
            </p>
            <p>3. Clicar no botão copiar texto e enviar para o cliente.</p>
            <p>
              4. Clicar no botão copiar calculadora e enviar no grupo do
              whatsapp antes de lançar no Belle.
            </p>
          </div>
          <div>
            <h3>Texto para Copiar</h3>
            <div
              style={{
                border: "1px solid #ddd",
                padding: "10px",
                borderRadius: "4px",
                whiteSpace: "pre-wrap",
                backgroundColor: "#f9f9f9",
                marginBottom: "10px",
                minHeight: "100px", // Para manter a área de texto com altura mínima
              }}
            >
              {`${textoPronto}\n\n${selectedText}`.trim()}
            </div>
            <button
              onClick={handleCopy}
              style={{
                padding: "8px 12px",
                backgroundColor: "#26b6b4",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Copiar Conteúdo
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CalculadoraOrcamentoConsultoras;
