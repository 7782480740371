import React, { useState, useEffect } from "react";
import { db, auth } from "../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  getDocs,
} from "firebase/firestore";
import "./Intercorrencia.css";
import { getCountFromServer } from "firebase/firestore";
import ImageUploader from "./ImageUploader";

const Intercorrencia = () => {
  const [intercorrencias, setIntercorrencias] = useState([]);
  const [newIntercorrencia, setNewIntercorrencia] = useState({
    cliente: "",
    cpf: "",
    telefone: "",
    dataProcedimento: "",
    dataPrimeiroContato: "",
    dataRetorno: "",
    numeroSessao: "",
    fototipo: "",
    metodo: "",
    reacao: "",
    fluencia: "",
    hz: "",
    acumuloMj: "",
    passadas: "",
    esteticista: "",
    areasAfetadas: "",
    ocorrencia: "",
    primeiraOrientacao: "",
    esteticistaAplicacao: "",
    dataSegundoContato: "",
    ocorrenciaSegundoContato: "",
    esteticistaSegundoContato: "",
    segundaOrientacao: "",
    dataTerceiroContato: "",
    ocorrenciaTerceiroContato: "",
    terceiraOrientacao: "",
    esteticistaTerceiroContato: "",
    imagens: [], // Adiciona o campo de imagens
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFicha, setSelectedFicha] = useState(null);
  const [loggedUser, setLoggedUser] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editingFicha, setEditingFicha] = useState({
    imagensSegundaOcorrencia: [], // Inicializado como array vazio
    imagensTerceiraOcorrencia: [], // Inicializado como array vazio
  });

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [intercorrenciaCount, setIntercorrenciaCount] = useState(0);
  const [zoomImage, setZoomImage] = useState(null);

  // Função para lidar com o upload de imagens
  const handleImagesUploaded = (uploadedURLs) => {
    setNewIntercorrencia((prev) => ({
      ...prev,
      imagens: [...prev.imagens, ...uploadedURLs],
    }));
  };

  // Função para adicionar imagens à segunda ocorrência
  const handleImagesSecondOccurrence = (uploadedURLs) => {
    setEditingFicha((prev) => ({
      ...prev,
      imagensSegundaOcorrencia: [
        ...(Array.isArray(prev.imagensSegundaOcorrencia)
          ? prev.imagensSegundaOcorrencia
          : []),
        ...uploadedURLs,
      ],
    }));
  };

  // Função para adicionar imagens à terceira ocorrência
  const handleImagesThirdOccurrence = (uploadedURLs) => {
    setEditingFicha((prev) => ({
      ...prev,
      imagensTerceiraOcorrencia: [
        ...(Array.isArray(prev.imagensTerceiraOcorrencia)
          ? prev.imagensTerceiraOcorrencia
          : []),
        ...uploadedURLs,
      ],
    }));
  };

  const openZoomModal = (url) => {
    setZoomImage(url);
  };

  const closeZoomModal = () => {
    setZoomImage(null);
  };

  useEffect(() => {
    const fetchCount = async () => {
      const count = await fetchIntercorrenciaCount();
      setIntercorrenciaCount(count);
    };

    fetchCount();
  }, []);

  // Função para obter a contagem de intercorrências
  const fetchIntercorrenciaCount = async () => {
    try {
      const collectionRef = collection(db, "intercorrencia"); // Altere "intercorrencia" pelo nome da sua coleção, se for diferente
      const snapshot = await getCountFromServer(collectionRef);
      return snapshot.data().count; // Retorna o número total de documentos
    } catch (error) {
      console.error("Erro ao obter a contagem de intercorrências:", error);
      return 0; // Retorna 0 em caso de erro
    }
  };

  // Função para formatar a data ISO para o formato brasileiro
  const formatDate = (isoDate) => {
    if (!isoDate) return ""; // Verifica se a data existe
    const date = new Date(isoDate);
    return date.toLocaleDateString("pt-BR"); // Formata para o padrão brasileiro DD/MM/YYYY
  };

  // Funções para abrir e fechar o modal de edição da ficha
  const handleEditIntercorrencia = (intercorrencia) => {
    setEditingFicha(intercorrencia);

    if (!intercorrencia.dataSegundoContato) {
      // Se não há dataSegundoContato, abrir modal para registrar os campos do segundo contato
      setIsEditModalOpen(true);
    } else if (!intercorrencia.dataTerceiroContato) {
      // Se não há dataTerceiroContato, abrir modal para registrar os campos do terceiro contato
      setIsEditModalOpen(true);
    } else {
      alert("Todos os registros de contato já foram preenchidos.");
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedUser({
          uid: user.uid,
        });
      } else {
        setLoggedUser({ email: "", uid: "" });
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchIntercorrencias = async () => {
      const querySnapshot = await getDocs(collection(db, "intercorrencia"));
      const fetchedIntercorrencias = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setIntercorrencias(fetchedIntercorrencias);
    };

    fetchIntercorrencias();
  }, []);

  const handleAddIntercorrencia = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Impede múltiplas submissões
    setIsSubmitting(true);
    try {
      // Substitui o UID pelo nome correspondente
      const esteticistaName =
        loggedUser.uid === "zGRay1ANHFZoDObAr4bllzJjc1F3"
          ? "Enny"
          : loggedUser.uid === "eVZjXlRT5dajNGhoTdyJBTfFjau2"
          ? "Isabella"
          : "UID não disponível";

      const formattedPhone = formatPhoneNumber(newIntercorrencia.telefone);
      if (!formattedPhone.match(/^\(\d{2}\) \d{5}-\d{4}$/)) {
        alert(
          "Telefone inválido! Por favor, insira um número no formato correto."
        );
        setIsSubmitting(false);
        return;
      }

      const formattedCPF = formatCPF(newIntercorrencia.cpf);

      const intercorrenciaWithUser = {
        ...newIntercorrencia,
        cpf: formattedCPF, // Salva o CPF formatado
        telefone: formattedPhone,
        esteticista: esteticistaName,
      };

      await addDoc(collection(db, "intercorrencia"), intercorrenciaWithUser);
      setIntercorrencias((prev) => [...prev, intercorrenciaWithUser]);
      setNewIntercorrencia({
        cliente: "",
        cpf: "",
        telefone: "",
        dataProcedimento: "",
        dataPrimeiroContato: "",
        dataRetorno: "",
        numeroSessao: "",
        fototipo: "",
        metodo: "",
        reacao: "",
        fluencia: "",
        hz: "",
        acumuloMj: "",
        passadas: "",
        esteticista: "",
        areasAfetadas: "",
        ocorrencia: "",
        primeiraOrientacao: "",
        esteticistaAplicacao: "",
        dataSegundoContato: "",
        ocorrenciaSegundoContato: "",
        esteticistaSegundoContato: "",
        segundaOrientacao: "",
        dataTerceiroContato: "",
        ocorrenciaTerceiroContato: "",
        terceiraOrientacao: "",
        esteticistaTerceiroContato: "",
        imagens: [],
      });
      setIsModalOpen(false);
    } catch (error) {
      console.error("Erro ao adicionar intercorrência:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseFicha = () => {
    setSelectedFicha(null);
  };

  const validateCPF = (cpf) => {
    // Remove caracteres não numéricos
    cpf = cpf.replace(/[^\d]+/g, "");

    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
      return false; // Verifica se todos os números são iguais ou se o tamanho é diferente de 11
    }

    let soma = 0;
    let resto;

    // Calcula o primeiro dígito verificador
    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;

    soma = 0;

    // Calcula o segundo dígito verificador
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return false;

    return true; // CPF válido
  };

  const formatCPF = (cpf) => {
    if (!cpf) return "";
    const cleaned = cpf.replace(/\D/g, ""); // Remove caracteres não numéricos
    return cleaned.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"); // Formata no padrão XXX.XXX.XXX-XX
  };

  const formatPhoneNumber = (phone) => {
    if (!phone) return ""; // Retorna vazio se o telefone não estiver presente

    // Remove todos os caracteres não numéricos
    const cleaned = phone.replace(/\D/g, "");

    // Formata para o padrão (XX) XXXXX-XXXX
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phone;
  };

  return (
    <section id="intercorrencia">
      <div className="intercorrencia-container">
        <h2>Gestão de Intercorrências</h2>
        <div className="contador-box">
          <p className="contador-titulo">Total de Intercorrências:</p>
          <p className="contador-numero">{intercorrenciaCount}</p>
        </div>
        <button
          className="adicionar-intercorrencia-btn"
          onClick={() => setIsModalOpen(true)}
        >
          Adicionar Ficha
        </button>
        {selectedFicha && (
          <div className="ficha-detalhes">
            <h3>Detalhes da Ficha</h3>
            <p>
              <strong>Cliente:</strong> {selectedFicha.cliente}
            </p>
            <p>
              <strong>CPF:</strong> {selectedFicha.cpf}
            </p>
            <p>
              <strong>Telefone:</strong> {selectedFicha.telefone}
            </p>
            <p>
              <strong>Data do Procedimento:</strong>{" "}
              {formatDate(selectedFicha.dataProcedimento)}
            </p>
            <p>
              <strong>Data do Primeiro Contato:</strong>{" "}
              {formatDate(selectedFicha.dataPrimeiroContato)}
            </p>
            <p>
              <strong>Data de Retorno:</strong>{" "}
              {formatDate(selectedFicha.dataRetorno)}
            </p>
            <p>
              <strong>Número da Sessão:</strong> {selectedFicha.numeroSessao}
            </p>
            <p>
              <strong>Fototipo:</strong> {selectedFicha.fototipo}
            </p>
            <p>
              <strong>Metodo:</strong> {selectedFicha.metodo}
            </p>
            <p>
              <strong>Reação:</strong> {selectedFicha.reacao}
            </p>
            <p>
              <strong>Fluencia (J/cm²):</strong> {selectedFicha.fluencia}
            </p>
            <p>
              <strong>Hz:</strong> {selectedFicha.hz}
            </p>
            <p>
              <strong>Acumulo MJ:</strong> {selectedFicha.acumuloMj}
            </p>
            <p>
              <strong>Passadas:</strong> {selectedFicha.passadas}
            </p>
            <p>
              <strong>Responsável do Primeiro Contato:</strong>{" "}
              {selectedFicha.esteticista}
            </p>
            <p>
              <strong>Areas Afetadas:</strong> {selectedFicha.areasAfetadas}
            </p>
            <p>
              <strong>Ocorrencia:</strong> {selectedFicha.ocorrencia}
            </p>
            <p>
              <strong>Esteticista Aplicacao:</strong>{" "}
              {selectedFicha.esteticistaAplicacao}
            </p>
            {selectedFicha.imagens && selectedFicha.imagens.length > 0 && (
              <div>
                <h4>Imagens Primeira Ocorrência:</h4>
                <ul style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                  {selectedFicha.imagens.map((url, index) => (
                    <li key={index} style={{ listStyleType: "none" }}>
                      <img
                        src={url}
                        alt={`Imagem ${index + 1}`}
                        onClick={() => openZoomModal(url)} // Abre o modal ao clicar
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                          cursor: "pointer",
                        }}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {selectedFicha.dataSegundoContato && (
              <>
                <p>
                  <strong>Data do Segundo Contato:</strong>{" "}
                  {formatDate(selectedFicha.dataSegundoContato)}
                </p>
                <p>
                  <strong>Ocorrencia do Segundo Contato: </strong>
                  {selectedFicha.ocorrenciaSegundoContato}
                </p>
                <p>
                  <strong>Responsável do Segundo Contato: </strong>
                  {selectedFicha.esteticistaSegundoContato}
                </p>
                <p>
                  {" "}
                  <strong>Segunda Orientação: </strong>{" "}
                  {selectedFicha.segundaOrientacao}
                </p>
                {selectedFicha.imagensSegundaOcorrencia &&
                  selectedFicha.imagensSegundaOcorrencia.length > 0 && (
                    <div>
                      <h4>Imagens Segunda Ocorrência:</h4>
                      <ul
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        {selectedFicha.imagensSegundaOcorrencia.map(
                          (url, index) => (
                            <li key={index} style={{ listStyleType: "none" }}>
                              <img
                                src={url}
                                alt={`Imagem ${index + 1}`}
                                onClick={() => openZoomModal(url)}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                  borderRadius: "5px",
                                  border: "1px solid #ccc",
                                  cursor: "pointer",
                                }}
                              />
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
              </>
            )}
            {selectedFicha.dataTerceiroContato && (
              <>
                <p>
                  <strong>Data do Terceiro Contato:</strong>{" "}
                  {formatDate(selectedFicha.dataTerceiroContato)}
                </p>
                <p>
                  <strong>Ocorrencia do Terceiro Contato: </strong>
                  {selectedFicha.ocorrenciaTerceiroContato}
                </p>
                <p>
                  <strong>Responsável do Terceiro Contato: </strong>
                  {selectedFicha.esteticistaTerceiroContato}
                </p>
                <p>
                  {" "}
                  <strong>Terceira Orientação: </strong>{" "}
                  {selectedFicha.terceiraOrientacao}
                </p>
                {selectedFicha.imagensTerceiraOcorrencia &&
                  selectedFicha.imagensTerceiraOcorrencia.length > 0 && (
                    <div>
                      <h4>Imagens Terceira Ocorrência:</h4>
                      <ul
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        {selectedFicha.imagensTerceiraOcorrencia.map(
                          (url, index) => (
                            <li key={index} style={{ listStyleType: "none" }}>
                              <img
                                src={url}
                                alt={`Imagem ${index + 1}`}
                                onClick={() => openZoomModal(url)}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                  borderRadius: "5px",
                                  border: "1px solid #ccc",
                                  cursor: "pointer",
                                }}
                              />
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
              </>
            )}
            <button className="close-btn" onClick={handleCloseFicha}>
              Fechar
            </button>
          </div>
        )}
        <div className="table-wrapper">
          <table>
            <thead>
              <tr>
                <th>Cliente</th>
                <th>CPF</th>
                <th>Data 1° Contato</th>
                <th>Telefone</th>
                <th>Data do Procedimento</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {intercorrencias.map((intercorrencia) => (
                <tr key={intercorrencia.id}>
                  <td>{intercorrencia.cliente}</td>
                  <td>{intercorrencia.cpf}</td>
                  <td>{formatDate(intercorrencia.dataPrimeiroContato)}</td>
                  <td>{intercorrencia.telefone}</td>
                  <td>{intercorrencia.dataProcedimento}</td>
                  <td className="button-td">
                    <button
                      className="view-btn"
                      onClick={() => setSelectedFicha(intercorrencia)}
                    >
                      Ver Ficha
                    </button>
                    <button
                      className="view-btn"
                      onClick={() => handleEditIntercorrencia(intercorrencia)}
                    >
                      Editar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {zoomImage && (
          <div
            className="modal-overlay"
            onClick={closeZoomModal}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <img
              src={zoomImage}
              alt="Imagem ampliada"
              style={{
                maxWidth: "90%",
                maxHeight: "90%",
                borderRadius: "10px",
              }}
            />
            <button
              onClick={closeZoomModal}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "#fff",
                border: "none",
                padding: "10px",
                borderRadius: "5px",
                cursor: "pointer",
                zIndex: 1001,
              }}
            >
              Fechar
            </button>
          </div>
        )}

        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3>Adicionar Intercorrência</h3>
              <form onSubmit={handleAddIntercorrencia}>
                <label>Cliente:</label>
                <input
                  type="text"
                  value={newIntercorrencia.cliente}
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      cliente: e.target.value,
                    })
                  }
                  required
                />
                <label>CPF:</label>
                <input
                  type="text"
                  value={newIntercorrencia.cpf}
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      cpf: e.target.value,
                    })
                  }
                  onBlur={async () => {
                    if (!validateCPF(newIntercorrencia.cpf)) {
                      alert("CPF inválido! Por favor, insira um CPF válido.");
                      return;
                    }
                  }}
                  pattern="^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$" // Regex para validação básica
                  title="Insira um CPF válido no formato 123.456.789-10 ou 12345678910"
                  required
                />
                <label>Telefone:</label>
                <input
                  type="text"
                  value={newIntercorrencia.telefone}
                  placeholder="(XX) XXXXX-XXXX"
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      telefone: e.target.value,
                    })
                  }
                  onBlur={() => {
                    const formattedPhone = formatPhoneNumber(
                      newIntercorrencia.telefone
                    );
                    if (!formattedPhone.match(/^\(\d{2}\) \d{5}-\d{4}$/)) {
                      alert(
                        "Telefone inválido! Insira um número no formato correto."
                      );
                    }
                  }}
                  required
                />
                <label>Data do Procedimento:</label>
                <input
                  type="date"
                  value={newIntercorrencia.dataProcedimento}
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      dataProcedimento: e.target.value,
                    })
                  }
                />

                <label>Data do Primeiro Contato:</label>
                <input
                  type="date"
                  value={newIntercorrencia.dataPrimeiroContato}
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      dataPrimeiroContato: e.target.value,
                    })
                  }
                />

                <label>Data de Retorno:</label>
                <input
                  type="date"
                  value={newIntercorrencia.dataRetorno}
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      dataRetorno: e.target.value,
                    })
                  }
                />

                <label>Número da Sessão:</label>
                <input
                  type="number"
                  value={newIntercorrencia.numeroSessao}
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      numeroSessao: e.target.value,
                    })
                  }
                />

                <label>Fototipo:</label>
                <select
                  value={newIntercorrencia.fototipo}
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      fototipo: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Selecione o Fototipo</option>
                  <option value="I">I</option>
                  <option value="II">II</option>
                  <option value="III">III</option>
                  <option value="IV">IV</option>
                  <option value="V">V</option>
                  <option value="VI">VI</option>
                </select>

                <label>Método:</label>
                <input
                  type="text"
                  value={newIntercorrencia.metodo}
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      metodo: e.target.value,
                    })
                  }
                />

                <label>Reação:</label>
                <select
                  value={newIntercorrencia.reacao}
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      reacao: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Selecione o Reação</option>
                  <option value="Alergia">Alergia</option>
                  <option value="Queimadura">Queimadura</option>
                  <option value="Mancha">Mancha</option>
                  <option value="Foliculite">Foliculite</option>
                </select>
                <label>Fluência (J/cm²):</label>
                <input
                  type="number"
                  step="0.01" // Permite valores decimais
                  value={newIntercorrencia.fluencia}
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      fluencia: e.target.value,
                    })
                  }
                  required
                />

                <label>Hz:</label>
                <input
                  type="number"
                  step="0.01" // Permite valores decimais
                  value={newIntercorrencia.hz}
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      hz: e.target.value,
                    })
                  }
                  required
                />

                <label>Acúmulo (mJ):</label>
                <input
                  type="number"
                  step="0.01" // Permite valores decimais
                  value={newIntercorrencia.acumuloMj}
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      acumuloMj: e.target.value,
                    })
                  }
                  required
                />

                <label>Passadas:</label>
                <input
                  type="number"
                  step="1" // Apenas números inteiros, ajuste conforme necessário
                  min="1" // Define o valor mínimo, ajuste conforme sua lógica
                  value={newIntercorrencia.passadas}
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      passadas: e.target.value,
                    })
                  }
                  required
                />
                <label>Responsável Pelo Registro:</label>
                <p>
                  {loggedUser.uid === "zGRay1ANHFZoDObAr4bllzJjc1F3"
                    ? "Enny"
                    : loggedUser.uid === "eVZjXlRT5dajNGhoTdyJBTfFjau2"
                    ? "Isabella"
                    : "Carregando UID..."}
                </p>
                <label>Áreas Afetadas:</label>
                <textarea
                  value={newIntercorrencia.areasAfetadas}
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      areasAfetadas: e.target.value,
                    })
                  }
                />

                <label>Ocorrência:</label>
                <textarea
                  value={newIntercorrencia.ocorrencia}
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      ocorrencia: e.target.value,
                    })
                  }
                />
                <label>Orientação:</label>
                <textarea
                  value={newIntercorrencia.primeiraOrientacao}
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      primeiraOrientacao: e.target.value,
                    })
                  }
                />
                <label>Esteticista da Aplicação:</label>
                <select
                  value={newIntercorrencia.esteticistaAplicacao}
                  onChange={(e) =>
                    setNewIntercorrencia({
                      ...newIntercorrencia,
                      esteticistaAplicacao: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Selecione um Esteticista</option>
                  <option value="Enny">Enny</option>
                  <option value="Isabella">Isabella</option>
                </select>
                <ImageUploader onImagesUploaded={handleImagesUploaded} />
                <button
                  type="submit"
                  className="save-btn"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Salvando..." : "Salvar"}
                </button>
                <button
                  type="button"
                  className="cancel-btn"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancelar
                </button>
              </form>
            </div>
          </div>
        )}
        {isEditModalOpen && editingFicha && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h3>
                {editingFicha.dataSegundoContato
                  ? "Registrar Terceiro Contato"
                  : "Registrar Segundo Contato"}
              </h3>
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  try {
                    const updatedData = { ...editingFicha };

                    if (!editingFicha.dataSegundoContato) {
                      updatedData.dataSegundoContato = new Date().toISOString(); // Exemplo: registra data atual
                      updatedData.ocorrenciaSegundoContato =
                        newIntercorrencia.ocorrenciaSegundoContato;
                      updatedData.segundaOrientacao =
                        newIntercorrencia.segundaOrientacao;
                      updatedData.imagensSegundaOcorrencia =
                        editingFicha.imagensSegundaOcorrencia;
                      updatedData.esteticistaSegundoContato =
                        loggedUser.uid === "zGRay1ANHFZoDObAr4bllzJjc1F3"
                          ? "Enny"
                          : loggedUser.uid === "eVZjXlRT5dajNGhoTdyJBTfFjau2"
                          ? "Isabella"
                          : "UID não disponível";
                    } else if (!editingFicha.dataTerceiroContato) {
                      updatedData.dataTerceiroContato =
                        new Date().toISOString(); // Exemplo: registra data atual
                      updatedData.ocorrenciaTerceiroContato =
                        newIntercorrencia.ocorrenciaTerceiroContato;
                      updatedData.terceiraOrientacao =
                        newIntercorrencia.terceiraOrientacao;
                      updatedData.imagensTerceiraOcorrencia =
                        editingFicha.imagensTerceiraOcorrencia;
                      updatedData.esteticistaTerceiroContato =
                        loggedUser.uid === "zGRay1ANHFZoDObAr4bllzJjc1F3"
                          ? "Enny"
                          : loggedUser.uid === "eVZjXlRT5dajNGhoTdyJBTfFjau2"
                          ? "Isabella"
                          : "UID não disponível";
                    }

                    // Atualiza no Firestore
                    const docRef = doc(db, "intercorrencia", editingFicha.id); // Use `doc` com a referência ao Firestore
                    await updateDoc(docRef, updatedData);

                    // Atualiza na tabela
                    setIntercorrencias((prev) =>
                      prev.map((item) =>
                        item.id === editingFicha.id
                          ? { ...item, ...updatedData }
                          : item
                      )
                    );

                    setIsEditModalOpen(false);
                    setEditingFicha(null);
                  } catch (error) {
                    console.error("Erro ao atualizar intercorrência:", error);
                  }
                }}
              >
                {!editingFicha.dataSegundoContato ? (
                  <>
                    <label>Ocorrência do Segundo Contato:</label>
                    <textarea
                      value={newIntercorrencia.ocorrenciaSegundoContato}
                      onChange={(e) =>
                        setNewIntercorrencia({
                          ...newIntercorrencia,
                          ocorrenciaSegundoContato: e.target.value,
                        })
                      }
                      required
                    />
                    <label>Orientação do Segundo Contato:</label>

                    <textarea
                      value={newIntercorrencia.segundaOrientacao}
                      onChange={(e) =>
                        setNewIntercorrencia({
                          ...newIntercorrencia,
                          segundaOrientacao: e.target.value,
                        })
                      }
                      required
                    />
                    <ImageUploader
                      onImagesUploaded={handleImagesSecondOccurrence}
                    />
                  </>
                ) : (
                  <>
                    <label>Ocorrência do Terceiro Contato:</label>
                    <textarea
                      value={newIntercorrencia.ocorrenciaTerceiroContato}
                      onChange={(e) =>
                        setNewIntercorrencia({
                          ...newIntercorrencia,
                          ocorrenciaTerceiroContato: e.target.value,
                        })
                      }
                      required
                    />
                    <label>Orientação do Terceiro Contato:</label>
                    <textarea
                      value={newIntercorrencia.terceiraOrientacao}
                      onChange={(e) =>
                        setNewIntercorrencia({
                          ...newIntercorrencia,
                          terceiraOrientacao: e.target.value,
                        })
                      }
                      required
                    />
                    <ImageUploader
                      onImagesUploaded={handleImagesThirdOccurrence}
                    />
                  </>
                )}
                <button
                  type="submit"
                  className="save-btn"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Salvando..." : "Salvar"}
                </button>

                <button
                  type="button"
                  className="cancel-btn"
                  onClick={() => setIsEditModalOpen(false)}
                >
                  Cancelar
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Intercorrencia;
