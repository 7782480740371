import React, { useState, useEffect } from "react";
//import './Imageslider.css';
import "./main.css"; // Estilo para o layout

const ImageSlider = () => {
  const images = [
    "/images/slide_1.jpg",
    "/images/slide_2.jpg",
    "/images/slide_3.jpg",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  // Função para mudar a imagem automaticamente a cada 2 segundos
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // 4 segundos

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  return (
    <div className="slider-container">
      <img src={images[currentIndex]} alt="Slider" className="slider-image" />
    </div>
  );
};

export default ImageSlider;
