import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { FirebaseAuthProvider } from "./components/FirebaseAuthContext";
import Header from "./components/Header";
import Home from "./components/Home";
import Contato from "./components/Contato";
import Footer from "./components/Footer";
import SubFooter from "./components/SubFooter";
import Perguntas from "./components/Perguntas";
import DermatoEsteticos from "./components/DermatoEsteticos";
import GanheSessoes from "./components/GanheSessoes";
import Depilacao from "./components/Depilacao";
import Pelometro from "./components/Pelometro";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import CalculadoraOrcamentoConsultoras from "./components/CalculadoraOrcamentoConsultoras";
import CalculadoraOrcamentoTecnicas from "./components/CalculadoraOrcamentoTecnicas";
import AnamneseFormDepilacao from "./components/AnamneseFormDepilacao";
import AnamneseFormOmer from "./components/AnamneseFormOmer";
import ControleGestao from "./components/ControleGestao";
import UserDashboard from "./components/UserDashboard";
import GestaoLeads from "./components/GestaoLeads";
import ConsultaVendas from "./components/ConsultaVendas";
import Administrativo from "./components/Administrativo";
import VendasPorCliente from "./components/VendasPorCliente";
import Intercorrencia from "./components/Intercorrencia";
import GestaoEstoque from "./components/GestaoEstoque";
import FolhaSalarial from "./components/FolhaSalarial";
import Unauthorized from "./components/Unauthorized"; // Página para acesso não autorizado
import NotFound from "./components/NotFound";

function App() {
  return (
    <Router>
      {" "}
      <FirebaseAuthProvider>
        {" "}
        {/* Envolve todo o aplicativo com FirebaseAuthProvider */}
        <div className="App">
          <Header />
          <div className="App-content">
            <Routes>
              {/* Rotas Públicas */}
              <Route path="/" element={<Home />} />
              <Route path="/perguntas" element={<Perguntas />} />
              <Route path="/contato" element={<Contato />} />
              <Route path="/dermato-esteticos" element={<DermatoEsteticos />} />
              <Route path="/ganhe-sessoes" element={<GanheSessoes />} />
              <Route path="/depilacao" element={<Depilacao />} />
              <Route path="/pelometro" element={<Pelometro />} />
              <Route
                path="/anamnesedepilacao"
                element={<AnamneseFormDepilacao />}
              />
              <Route path="/anamneseomer" element={<AnamneseFormOmer />} />
              {/* Rota de Login Unificada */}
              <Route path="/login" element={<Login />} />
              {/* Rota para acesso não autorizado */}
              <Route path="/naoautorizado" element={<Unauthorized />} />
              {/* Rota 404 */}
              <Route path="*" element={<NotFound />} />
              {/* Rotas Protegidas */}
              <Route
                path="/calculadoraconsultoras"
                element={
                  <PrivateRoute requiredRoles={["admin", "coord", "user"]}>
                    <CalculadoraOrcamentoConsultoras />
                  </PrivateRoute>
                }
              />
              <Route
                path="/calculadoraaplicadoras"
                element={
                  <PrivateRoute requiredRoles={["admin", "coord", "user"]}>
                    <CalculadoraOrcamentoTecnicas />
                  </PrivateRoute>
                }
              />
              <Route
                path="/administrativo"
                element={
                  <PrivateRoute requiredRoles={["admin", "coord", "user"]}>
                    <Administrativo />
                  </PrivateRoute>
                }
              />
              <Route
                path="/controledegestao"
                element={
                  <PrivateRoute requiredRoles={["admin", "coord"]}>
                    <ControleGestao />
                  </PrivateRoute>
                }
              />
              <Route
                path="/consultavendas"
                element={
                  <PrivateRoute requiredRoles={["admin", "coord"]}>
                    <ConsultaVendas />
                  </PrivateRoute>
                }
              />
              <Route
                path="/folhasalarial"
                element={
                  <PrivateRoute requiredRoles={["admin", "teste"]}>
                    <FolhaSalarial />
                  </PrivateRoute>
                }
              />
              <Route
                path="/user-dashboard"
                element={
                  <PrivateRoute requiredRoles={["user", "teste"]}>
                    <UserDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/gestaoleads"
                element={
                  <PrivateRoute requiredRoles={["admin", "coord", "user"]}>
                    <GestaoLeads />
                  </PrivateRoute>
                }
              />
              <Route
                path="/vendasporcliente"
                element={
                  <PrivateRoute requiredRoles={["admin", "coord", "user"]}>
                    <VendasPorCliente />
                  </PrivateRoute>
                }
              />{" "}
              <Route
                path="/intercorrencia"
                element={
                  <PrivateRoute requiredRoles={["admin", "coord", "user"]}>
                    <Intercorrencia />
                  </PrivateRoute>
                }
              />
              <Route
                path="/gestaoestoque"
                element={
                  <PrivateRoute requiredRoles={["admin", "teste"]}>
                    <GestaoEstoque />
                  </PrivateRoute>
                }
              />
            </Routes>
          </div>
          <SubFooter />
          <Footer />
        </div>
      </FirebaseAuthProvider>
    </Router>
  );
}

export default App;
