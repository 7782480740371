import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./main.css"; // Estilo para o layout

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Função para fechar o menu ao clicar em um link no mobile
  const closeMenu = () => {
    if (menuOpen) setMenuOpen(false);
  };

  return (
    <section id="header">
      <header>
        <div className="logo">
          <Link to="/" onClick={closeMenu}>
            <img src="/images/logo.png" alt="maislaser logo" />
          </Link>
        </div>

        {/* Menu de navegação */}
        <nav className={menuOpen ? "open" : ""}>
          <ul>
            <li>
              <Link to="/" onClick={closeMenu}>
                Início
              </Link>
            </li>
            <li>
              <Link to="/depilacao" onClick={closeMenu}>
                Depilação a Laser
              </Link>
            </li>
            <li>
              <Link to="/dermato-esteticos" onClick={closeMenu}>
                Dermato Estéticos
              </Link>
            </li>
            <li>
              <Link to="/ganhe-sessoes" onClick={closeMenu}>
                Ganhe 3 Sessões Gratuitas
              </Link>
            </li>
            <li>
              <Link to="/pelometro" onClick={closeMenu}>
                Pelômetro
              </Link>
            </li>
          </ul>
        </nav>

        {/* Overlay para escurecer o fundo no mobile */}
        {menuOpen && <div className="menu-overlay" onClick={toggleMenu}></div>}

        {/* Ícone do menu hambúrguer */}
        <div className="hamburger" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </header>
    </section>
  );
}

export default Header;
