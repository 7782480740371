import React, { useState, memo } from "react";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../config/firebase";

const ImageUploader = memo(({ onImagesUploaded }) => {
  const [isUploading, setIsUploading] = useState(false);

  const handleImageUpload = async (files) => {
    if (!files || files.length === 0) {
      alert("Por favor, selecione pelo menos uma imagem.");
      return;
    }

    setIsUploading(true);

    const uploadedURLs = [];
    try {
      for (const file of files) {
        const storageRef = ref(
          storage,
          `intercorrencia/${Date.now()}_${file.name}`
        );
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        uploadedURLs.push(downloadURL);
      }

      onImagesUploaded(uploadedURLs); // Envia as URLs para o componente pai
      alert("Imagens enviadas com sucesso!");
    } catch (error) {
      console.error("Erro ao enviar imagens:", error);
      alert("Erro ao enviar as imagens.");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div>
      <label>Imagens:</label>
      <input
        type="file"
        multiple
        accept="image/*"
        onChange={(e) => handleImageUpload(e.target.files)}
        style={{ marginBottom: "10px" }}
        disabled={isUploading}
      />
      {isUploading && <p>Enviando imagens...</p>}
    </div>
  );
});

export default ImageUploader;
