import React, { useState, useEffect } from "react";
import { db } from "../config/firebase";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import "./GestaoEstoque.css";

const GestaoEstoque = () => {
  const [products, setProducts] = useState([]); // Lista de produtos no grupo selecionado
  const [currentGroup, setCurrentGroup] = useState(""); // Grupo selecionado para visualização de estoque
  const [showProducts, setShowProducts] = useState(false); // Controle de visibilidade da lista de produtos
  const [minProducts, setMinProducts] = useState([]); // Produtos em estoque crítico
  const [minProcedimentoCount, setMinProcedimentoCount] = useState(0); // Contador de críticos para Procedimento
  const [minLimpezaCount, setMinLimpezaCount] = useState(0); // Contador de críticos para Limpeza
  const [minClienteCount, setMinClienteCount] = useState(0); // Contador de críticos para Cliente
  const [criticalGroup, setCriticalGroup] = useState(""); // Grupo crítico selecionado para visualização

  const handleRefresh = async () => {
    try {
      // Recalcular os contadores críticos
      const procedimentoSnapshot = await getDocs(
        collection(db, "produtoprocedimento")
      );
      const limpezaSnapshot = await getDocs(collection(db, "produtolimpeza"));
      const clienteSnapshot = await getDocs(collection(db, "produtocliente"));

      const procedimentoCount = procedimentoSnapshot.docs.filter(
        (doc) => doc.data().min >= doc.data().quantity
      ).length;

      const limpezaCount = limpezaSnapshot.docs.filter(
        (doc) => doc.data().min >= doc.data().quantity
      ).length;

      const clienteCount = clienteSnapshot.docs.filter(
        (doc) => doc.data().min >= doc.data().quantity
      ).length;

      // Atualizar os contadores
      setMinProcedimentoCount(procedimentoCount);
      setMinLimpezaCount(limpezaCount);
      setMinClienteCount(clienteCount);

      // Limpar estados relacionados
      setProducts([]);
      setShowProducts(false);
      setCriticalGroup("");
    } catch (error) {
      console.error("Erro ao atualizar dados:", error);
      alert("Erro ao atualizar os dados. Tente novamente.");
    }
  };

  useEffect(() => {
    const calculateCriticalCounts = async () => {
      try {
        // Obtém os produtos de cada grupo
        const procedimentoSnapshot = await getDocs(
          collection(db, "produtoprocedimento")
        );
        const limpezaSnapshot = await getDocs(collection(db, "produtolimpeza"));
        const clienteSnapshot = await getDocs(collection(db, "produtocliente"));

        // Filtrar produtos críticos
        const procedimentoCount = procedimentoSnapshot.docs.filter(
          (doc) => doc.data().min >= doc.data().quantity
        ).length;

        const limpezaCount = limpezaSnapshot.docs.filter(
          (doc) => doc.data().min >= doc.data().quantity
        ).length;

        const clienteCount = clienteSnapshot.docs.filter(
          (doc) => doc.data().min >= doc.data().quantity
        ).length;

        // Atualiza os contadores
        setMinProcedimentoCount(procedimentoCount);
        setMinLimpezaCount(limpezaCount);
        setMinClienteCount(clienteCount);
      } catch (error) {
        console.error("Erro ao calcular contadores críticos:", error);
      }
    };

    calculateCriticalCounts();
  }, []); // Executa uma vez ao carregar o componente

  const fetchProducts = async (group) => {
    try {
      const querySnapshot = await getDocs(collection(db, group));
      const productList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productList); // Atualiza a lista de produtos do grupo selecionado
    } catch (error) {
      console.error("Erro ao buscar produtos:", error);
    }
  };

  const handleAddSale = async (id, currentQuantity, saleQuantity) => {
    if (!saleQuantity || saleQuantity <= 0) {
      alert("Por favor, insira uma quantidade válida!");
      return;
    }

    if (currentQuantity < saleQuantity) {
      alert("Estoque insuficiente para esse consumo!");
      return;
    }

    try {
      const productRef = doc(db, currentGroup, id);
      await updateDoc(productRef, {
        quantity: parseInt(currentQuantity, 10) - parseInt(saleQuantity, 10),
      });
      fetchProducts(currentGroup); // Atualiza a lista de produtos após a venda
    } catch (error) {
      alert("Erro ao realizar o consumo. Tente novamente.");
    }
  };

  const handleRestock = async (id, currentQuantity, restockQuantity) => {
    if (!restockQuantity || restockQuantity <= 0) {
      alert("Por favor, insira uma quantidade válida!");
      return;
    }

    try {
      const productRef = doc(db, currentGroup, id);
      await updateDoc(productRef, {
        quantity: parseInt(currentQuantity, 10) + parseInt(restockQuantity, 10),
      });
      fetchProducts(currentGroup); // Atualiza a lista de produtos após reposição
    } catch (error) {
      console.error("Erro ao atualizar o estoque:", error);
      alert("Erro ao atualizar o estoque. Tente novamente.");
    }
  };

  const handleShowCriticalGroup = async (group) => {
    setCriticalGroup(group);
    try {
      const querySnapshot = await getDocs(collection(db, group));
      const productList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Filtrar produtos críticos
      const criticalProducts = productList.filter(
        (product) => product.min >= product.quantity
      );
      setMinProducts(criticalProducts);
    } catch (error) {
      console.error("Erro ao buscar produtos críticos:", error);
    }
  };

  const handleShowProducts = (group) => {
    setCurrentGroup(group);
    setShowProducts(true);
    fetchProducts(group); // Busca os produtos do grupo selecionado
  };

  const handleHideProducts = () => {
    setShowProducts(false);
    setProducts([]); // Limpa os produtos ao esconder a lista
  };

  return (
    <section id="gestao-estoque">
      <button className="refresh-button" onClick={handleRefresh}>
        Atualizar Estoque
      </button>

      <h2>Gestão de Estoque</h2>
      <div className="contador-criticos">
        {/* Contador de Produtos de Procedimento */}
        <button
          className="contador-criticos-box"
          onClick={() => handleShowCriticalGroup("produtoprocedimento")}
        >
          <p className="contador-criticos-titulo">
            Procedimento em Estoque Crítico:{" "}
          </p>
          <p className="contador-criticos-numero">{minProcedimentoCount}</p>
        </button>

        {/* Contador de Produtos de Limpeza */}
        <button
          className="contador-criticos-box"
          onClick={() => handleShowCriticalGroup("produtolimpeza")}
        >
          <p className="contador-criticos-titulo">
            Limpeza em Estoque Crítico:{" "}
          </p>
          <p className="contador-criticos-numero">{minLimpezaCount}</p>
        </button>

        {/* Contador de Produtos de Cliente */}
        <button
          className="contador-criticos-box"
          onClick={() => handleShowCriticalGroup("produtocliente")}
        >
          <p className="contador-criticos-titulo">
            Cliente em Estoque Crítico:{" "}
          </p>
          <p className="contador-criticos-numero">{minClienteCount}</p>
        </button>
      </div>
      {criticalGroup && (
        <div className="min-products">
          <h3>Produtos com Estoque Crítico</h3>
          <div className="min-products-container">
            {minProducts.map((item) => (
              <div key={item.id} className="min-product-item">
                <p>{item.name}</p>
                <p>Quantidade: {item.quantity}</p>
              </div>
            ))}
          </div>
          <button
            className="hide-button"
            onClick={() => setCriticalGroup("")} // Fecha a visualização de produtos críticos
          >
            Fechar
          </button>
        </div>
      )}
      <div className="group-buttons">
        <button
          className={currentGroup === "produtoprocedimento" ? "active" : ""}
          onClick={() => handleShowProducts("produtoprocedimento")}
        >
          Produtos de Procedimento
        </button>
        <button
          className={currentGroup === "produtolimpeza" ? "active" : ""}
          onClick={() => handleShowProducts("produtolimpeza")}
        >
          Produtos de Limpeza
        </button>
        <button
          className={currentGroup === "produtocliente" ? "active" : ""}
          onClick={() => handleShowProducts("produtocliente")}
        >
          Produtos do Cliente
        </button>
      </div>

      {showProducts && (
        <div className="product-list">
          <h3>
            Estoque Atual:{" "}
            {currentGroup === "produtoprocedimento"
              ? "Procedimentos"
              : currentGroup === "produtolimpeza"
              ? "Limpeza"
              : "Clientes"}
          </h3>
          <ul>
            {products.map((item) => (
              <li key={item.id}>
                <p>{item.name}</p>
                <p>Quantidade: {item.quantity}</p>
                <div className="actions">
                  <button
                    onClick={() =>
                      handleAddSale(
                        item.id,
                        item.quantity,
                        prompt("Quantidade para consumir:")
                      )
                    }
                  >
                    Consumir
                  </button>
                  <button
                    onClick={() =>
                      handleRestock(
                        item.id,
                        item.quantity,
                        prompt("Quantidade para repor:")
                      )
                    }
                  >
                    Repor
                  </button>
                </div>
              </li>
            ))}
          </ul>
          <button className="hide-button" onClick={handleHideProducts}>
            Fechar
          </button>
        </div>
      )}
    </section>
  );
};

export default GestaoEstoque;
