import React, { useState } from "react";
//import './Perguntas.css';
import { Link } from "react-router-dom";
import "./main.css"; // Estilo para o layout

function Perguntas() {
  const [activeSection, setActiveSection] = useState("depilacao"); // Para escolher entre depilação e dermato
  const [activeQuestion, setActiveQuestion] = useState(null); // Para controlar a pergunta ativa

  const toggleQuestion = (question) => {
    // Alterna entre mostrar ou esconder a resposta da pergunta
    setActiveQuestion(activeQuestion === question ? null : question);
  };

  return (
    <section id="perguntas">
      <h2>Perguntas Frequentes</h2>
      <div>
        <button
          onClick={() =>
            setActiveSection(activeSection === "depilacao" ? null : "depilacao")
          }
        >
          Depilação a laser
        </button>
        <button
          onClick={() =>
            setActiveSection(activeSection === "dermato" ? null : "dermato")
          }
        >
          Dermato Estéticos
        </button>
      </div>
      {activeSection === "depilacao" && (
        <ul className="perguntas">
          <li onClick={() => toggleQuestion(1)}>
            1. Quais as vantagens da depilação a laser?
            {activeQuestion === 1 && (
              <p>
                • Mais eficácia;
                <br />• Mais economia;
                <br />• Maior autoestima;
                <br />• Mais bem-estar
              </p>
            )}
          </li>
          <li onClick={() => toggleQuestion(2)}>
            2. A depilação é cara em relação a outras técnicas?
            {activeQuestion === 2 && (
              <p>
                O custo da depilação a laser é muito mais vantajoso que outros
                métodos de depilação, principalmente quando comparamos ao longo
                dos anos. É possível comparar os custos da depilação a laser com
                outros métodos como depilação com láminas ou cera por exemplo.{" "}
                <br />
                Acesse nosso pelômetro e faça uma comparação:
                <Link to="/pelometro" className="custom-link">
                  https://www.maislaser.com.br/pelometro-depilacao-a-laser
                </Link>
              </p>
            )}
          </li>
          <li onClick={() => toggleQuestion(3)}>
            3. Quais áreas podem ser tratadas?
            {activeQuestion === 3 && (
              <p>
                • Feminino: axilas, braços, glúteos, antebraços, aréolas, buço,
                coxas, entre seios, face lateral, faixa de coxa, glabela, inter
                glúteos, linha alba, lombae, meia perna, mãos e dedos, mento
                (queixo), nuca, perianal, perna inteira, pés e dedos, pescoço,
                rosto, testa, virilha íntima, virilha completa e virilha simples
                (biquini). <br />
                <br />
                • Masculino: abdômen, antebraços, axilas, barba, base do pênis,
                braços, cabeça, costeletas, coxas, dorsal, glabela, glúteos,
                inter glúteos, lombar, mãos e dedos, meia perna, maxilar, nariz,
                nuca, ombros, orelhas, perianal, perna inteira, pés e dedos,
                pescoço, testa, tórax e virilha.
                <br />
              </p>
            )}
          </li>
          <li onClick={() => toggleQuestion(4)}>
            4. Quem não pode fazer depilação a laser?
            {activeQuestion === 4 && (
              <p>
                Algumas das restrições são: Pelos na área de aplicação na cor
                brancos, ruivos e loiros (ausência de resultados), gestante,
                marca-passo, câncer, aplicação sobre a área de tatuagem,
                melasma, cicatrizes queloides, rosácea, feridas abertas ou
                lesões de pele na região de aplicação.
              </p>
            )}
          </li>
          <li onClick={() => toggleQuestion(5)}>
            5. Quem tem tatuagem pode fazer depilação a laser?
            {activeQuestion === 5 && (
              <p>
                No local da tatuagem a depilação a laser não pode ser realizada.
                A depilação a laser só pode ser feita na pele onde não há
                pigmento.
              </p>
            )}
          </li>
          <li onClick={() => toggleQuestion(6)}>
            6. Quem amamenta pode fazer depilação a laser?
            {activeQuestion === 6 && (
              <p>
                É recomendado aguardar até 3 meses após o nascimento do bebê.
                <br />
                Visto que no período de lactação inicial, a mulher ainda está em
                fase de recuperação pós-parto.
              </p>
            )}
          </li>
          <li onClick={() => toggleQuestion(7)}>
            7. Para fazer depilação a laser o pelo tem que estar grande?
            {activeQuestion === 7 && (
              <p>
                Não, o laser de diodo por fibra ótica é mais pontual e
                direcionado. Essa alta tecnologia permite que o laser leia o
                pelo dentro da pele e você não precisa vir com o pelo aparente.
                <br />O preparo da área (depilar) deve ser realizado no máximo
                24 horas antes da sessão.
              </p>
            )}
          </li>
          <li onClick={() => toggleQuestion(8)}>
            8. Quantas sessões são necessárias para eliminar os pelos
            completamente?
            {activeQuestion === 8 && (
              <p>
                Já na primeira sessão de depilação a laser, é possível perceber
                resultados. No entanto, a remoção completa dos pelos pode variar
                de acordo com as características individuais de cada pessoa.{" "}
                <br />
                Em média, são necessárias entre 10 e 12 sessões para uma
                depilação eficaz, pois a cada sessão cerca de 8 a 10% dos pelos
                são eliminados, dependendo da fase de crescimento em que se
                encontram. Por isso, é fundamental realizar uma avaliação
                detalhada para definir a quantidade de sessões adequadas às suas
                necessidades.
              </p>
            )}
          </li>
          <li onClick={() => toggleQuestion(9)}>
            9. Depilação a laser dói?
            {activeQuestion === 9 && (
              <p>
                A Maislaser oferece um dos métodos de depilação a laser mais
                avançados e confortáveis do mercado. Nosso equipamento, de
                tecnologia exclusiva, utiliza tecnologia de diodo com três
                comprimentos de onda (Alexandrite, Diodo e NdYag), garantindo
                segurança e eficácia para todos os tipos de pele. Com ponteira
                resfriada a -5°C, o equipamento proporciona uma experiência de
                aplicação com o máximo de conforto.
              </p>
            )}
          </li>
          <li onClick={() => toggleQuestion(10)}>
            10. A depilação à laser é mesmo definitiva?
            {activeQuestion === 10 && (
              <p>
                Sim. A depilação a laser geralmente requer entre 10 e 12 sessões
                para garantir a remoção completa dos pelos. Após a cauterização
                do folículo, o pelo não volta a crescer.
                <br />
                No entanto, em alguns casos, condições hormonais podem estimular
                o surgimento de novos pelos, podendo ser necessárias sessões de
                manutenção no futuro. <br />
                Cabe ressaltar que pelos ruivos, loiros e brancos não respondem
                ao tratamento a laser, ou seja, não são removidos.
              </p>
            )}
          </li>
          <li onClick={() => toggleQuestion(11)}>
            11. Qual a diferença entre virilha biquíni e virilha completa?
            {activeQuestion === 11 && (
              <p>
                Virilha biquíni é a região lateral da calcinha e virilha
                completa é a depilação completa da região íntima, incluindo os
                grandes lábios.
              </p>
            )}
          </li>
          <li onClick={() => toggleQuestion(12)}>
            12. Na depilação íntima masculina, a região do saco escrotal também
            é incluída no procedimento?
            {activeQuestion === 12 && (
              <p>
                Não, em nossa clínica, a depilação da região íntima masculina
                abrange apenas a base do pênis e a virilha.
              </p>
            )}
          </li>
        </ul>
      )}
      {activeSection === "dermato" && (
        <ul>
          <li onClick={() => toggleQuestion(7)}>
            1. Como funciona?
            {activeQuestion === 7 && (
              <p>
                A máquina a laser Omer Smart funciona através da emissão de
                pulsos de luz laser de alta energia (ND Yag), que são
                direcionados para a área da pele a ser tratada. O tipo de laser
                e as configurações são ajustados de acordo com o tipo de
                tratamento e as características da pele do paciente.
              </p>
            )}
          </li>
          <li onClick={() => toggleQuestion(8)}>
            2. Qual laser utilizamos?
            {activeQuestion === 8 && (
              <p>
                Utilizamos o laser de ND YAG, com o comprimento de ondas 1064nm
                e 532mm.
              </p>
            )}
          </li>
          <li onClick={() => toggleQuestion(9)}>
            3. Quais tratamentos possuimos?
            {activeQuestion === 9 && (
              <p>
                Contamos com mais de 22 procedimentos dermatos estéticos:
                <br />
                Remoção de tatuagem pretas riscada/desenho, remoção de tatuagem
                cobertura (Cover-up), remoção de tatuagem Coloridas, Remoção de
                micropigmentação, peeling de carbono (Blackpeel/Laser
                Peel/Hollywood Peel), melasma, mancha senil (mãos/colo/braços),
                hiperpigmentação pós inflamatória, efélides (sardas), manchas
                pós laser depilação ou queimadura, BBGLOW (drug delivery/mesclas
                pós-laser), rejuvenescimento Facial, controle da oleosidade e
                fechamentos dos poros, acne juvenil e adulta, cicatrizes e
                manchas pós acne, foliculite (barba/áreas intimas e sensíveis,
                clareamento íntimo, estrias brancas e vermelhas, cicatrizes
                pigmentadas, olheiras pigmentadas, onicomicose (fungo nas unhas
                dos pés e das mãos), rosáseas (vermelhidão facial),
                telangectasia (vasinhos faciais)
              </p>
            )}
          </li>
        </ul>
      )}
    </section>
  );
}

export default Perguntas;
