import React from "react";
//import './Home.css';
import ImageSlider from "./Imageslider"; // Importe o slider
import Contato from "./Contato"; // Importe o componente de contato
import Perguntas from "./Perguntas";
import "./main.css"; // Estilo para o layout

function Home() {
  return (
    <section id="home">
      <div className="hero">
        <ImageSlider />
        <h2>
          Bem-vindo à maislaser <br />
          by Ana Hickmann
        </h2>
        <h3>Barra da Tijuca - RJ</h3>
        <p className="home-text">
          Na Maislaser, oferecemos tratamentos de depilação e procedimentos
          dermato-estéticos a laser com tecnologia de ponta e exclusiva. Nossa
          marca é referência em segurança e eficácia para todos os tons de pele,
          incluindo as bronzeadas. Além da depilação a laser, nossos tratamentos
          dermato-estéticos abrangem desde rejuvenescimento facial até a remoção
          de manchas e cicatrizes, garantindo resultados duradouros e o máximo
          de conforto para sua pele.
        </p>
        <Perguntas />
        <Contato /> {/* Reintroduzindo o Contato dentro da Home */}
      </div>
    </section>
  );
}

export default Home;
