import React, { useState } from "react";
import "./main.css";
import emailjs from "emailjs-com";

const AnamneseFormDepilacao = () => {
  // Estado para controlar se a imagem está expandida ou Não
  const [isExpanded, setIsExpanded] = useState(false);

  // Função para alternar entre a versão expandida e reduzida da imagem
  const toggleImageSize = () => {
    setIsExpanded(!isExpanded);
  };

  const [formData, setFormData] = useState({
    nome: "",
    sexo: "FEMININO",
    depilacaoLaser: "",
    metodoDepilacao: "",
    descricaoDepilacao: "",
    tratamentoMedico: "",
    descricaoTratamento: "",
    usoMedicamento: "",
    descricaoMedicamento: "",
    usoIsotretinoina: "",
    dataIsotretinoina: "",
    alergiaPele: "",
    descricaoAlergia: "",
    doencaAutoimune: "",
    descricaoAutoimune: "",
    historicoCancer: "",
    descricaoCancer: "",
    cicatrizQueloide: "",
    descricaoCicatriz: "",
    melasmaRosacea: "",
    descricaoMelasma: "",
    usoAcido: "",
    descricaoAcido: "",
    implanteMetalico: "",
    descricaoImplante: "",
    herpes: "",
    exposicaoSol: "",
    reposicaoHormonal: "",
    descricaoReposicao: "",
    gestante: "",
    amamentando: "",
    tempoAmamentacao: "",
    ovarioPolicistico: "",
    fototipo: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Mapeamento das perguntas completas com suas chaves
  const perguntas = {
    nome: "Qual é o seu nome?",
    depilacaoLaser:
      "1. Já realizou algum tratamento de Depilação a Laser ou Luz Intensa Pulsada?",
    metodoDepilacao: "2. Qual o método de depilação atual?",
    descricaoDepilacao: "Descreva o método depilatório",
    tratamentoMedico:
      "3. Está sob algum tratamento médico ou estético no momento?",
    descricaoTratamento: "Descreva tratamento médico ou estético no momento:",
    usoMedicamento:
      "4. Está tomando algum medicamento de uso contínuo ou tomou alguma medicação nos últimos 15 dias?",
    descricaoMedicamento:
      "4.1 Descreva medicamento contínuo ou nos últimos  15 dias:",
    usoIsotretinoina:
      "5. Está em uso de isotretinoína (Roacutan) via oral ou já tomou este medicamento?",
    dataIsotretinoina: "Data de início e fim do tratamento Roacutan:",
    alergiaPele:
      "6. Tem alergia de pele (dermatite atópica ou dermatite de contato) ou diagnóstico de foliculite decalvante?",
    descricaoAlergia: "Descreva alergia ou foliculite decalvante:",
    doencaAutoimune:
      "7. Possui alguma doença autoimune (psoríase, vitiligo, lúpus ou líquen plano)? Possui epilepsia?",
    descricaoAutoimune: "Descreva doença autoimune:",
    historicoCancer:
      "8. Tem histórico de câncer ou está em tratamento de câncer?",
    descricaoCancer: "Descreva o tipo de câncer:",
    cicatrizQueloide:
      "9. Possui cicatriz hipertrófica, queloide, queimaduras ou enxerto cutâneo na área de aplicação do laser?",
    descricaoCicatriz: "Descreva cicatriz:",
    melasmaRosacea:
      "10. Possui melasma, rosácea, tatuagens ou micropigmentação na área de aplicação do laser?",
    descricaoMelasma:
      "Descreva melasma, rosácea, tatuagem ou micropigmentação:",
    usoAcido: "11. Usou ou está usando algum ácido na área a ser tratada?",
    descricaoAcido: "Descreva o ácido:",
    implanteMetalico:
      "12. Possui algum implante metálico, prótese no corpo ou marca-passo?",
    descricaoImplante: "Descreva implante:",
    herpes: "13. Já teve herpes?",
    exposicaoSol:
      "14. Esteve exposto(a) ao sol, utilizou autobronzeadores ou fez bronzeamento artificial nos últimos 7 a 30 dias?",
    reposicaoHormonal:
      "15. Faz tratamento para reposição hormonal? Já fez ou faz uso de anabolizantes?",
    descricaoReposicao: "Descreva reposição hormonal:",
    gestante: "16. Está gestante ou pretende engravidar nos próximos meses?",
    amamentando: "17. Está amamentando?",
    tempoAmamentacao: "1Amamenta há quanto tempo:",
    ovarioPolicistico: "18. Possui ovário policístico?",
    fototipo: "Qual é o seu fototipo?",
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Verificar se todos os campos obrigatórios foram preenchidos

    let camposObrigatorios = [
      "nome",
      "sexo",
      "depilacaoLaser",
      "metodoDepilacao",
      "tratamentoMedico",
      "usoMedicamento",
      "usoIsotretinoina",
      "alergiaPele",
      "doencaAutoimune",
      "historicoCancer",
      "cicatrizQueloide",
      "melasmaRosacea",
      "usoAcido",
      "implanteMetalico",
      "herpes",
      "exposicaoSol",
      "reposicaoHormonal",
    ];

    // Adiciona campos específicos se o sexo for "FEMININO"
    if (formData.sexo === "FEMININO") {
      camposObrigatorios = camposObrigatorios.concat([
        "gestante",
        "amamentando",
        "ovarioPolicistico",
      ]);
    }

    // Verifica se algum dos campos obrigatórios está vazio
    const camposVazios = camposObrigatorios.some(
      (campo) => formData[campo] === ""
    );

    // Encontre os campos que estão vazios
    if (camposVazios) {
      alert(
        "Por favor, preencha todos os campos obrigatórios antes de enviar."
      );
      return; // Interrompe o envio se houver campos vazios
    }

    // Processar e enviar a mensagem para o WhatsApp
    const filteredData = Object.entries(formData)
      .filter(
        ([key, value]) =>
          value === "Sim" ||
          (key.includes("descricao") && value) ||
          key === "nome" ||
          key === "metodoDepilacao" ||
          key === "fototipo"
      )
      .map(([key, value]) => {
        const formattedKey = perguntas[key] || key;
        return `${formattedKey} ${value}`;
      })
      .join("\n\n"); // Adiciona uma linha em branco entre cada resposta para facilitar a leitura

    const hasSimResponse = Object.values(formData).includes("Sim");

    // Defina a mensagem de WhatsApp com base na presença de respostas "Sim"
    const whatsappMessage = hasSimResponse
      ? `*Olá, já preenchi meu formulário e peço atenção às perguntas que foram respondidas com "Sim".*:\n\n${filteredData}`
      : `*Olá, já preenchi meu formulário e todas as perguntas foram respondidas com "Não".*:\n\n${filteredData}`;
    const whatsappUrl = `https://wa.me/5521991261684?text=${encodeURIComponent(
      whatsappMessage
    )}`;

    window.open(whatsappUrl, "_blank");
    emailjs
      .send(
        "service_6qnkhgc",
        "template_qgsutyq",
        {
          message: filteredData, // Esta linha é onde o conteúdo do formulário será enviado
          ...formData, // opcional: envia todos os dados do formulário individualmente
        },
        "ifwpKhvtR2dzVE6kr"
      )
      .then(
        (result) => {
          console.log("Email successfully sent:", result.text);
        },
        (error) => {
          console.log("Failed to send email:", error.text);
        }
      );
  };

  return (
    <div className="anamnese">
      <div className="anamnese-form" style={{ padding: "20px" }}>
        <h2>Formulário de Anamnese - Depilação</h2>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div>
            <label>Preencha nome completo</label>
            <input
              type="text"
              name="nome"
              value={formData.nome}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Sexo</label>
            <div className="option-group">
              <input
                type="radio"
                name="sexo"
                value="MASCULINO"
                onChange={handleChange}
              />{" "}
              Masculino
              <input
                type="radio"
                name="sexo"
                value="FEMININO"
                onChange={handleChange}
              />{" "}
              Feminino
            </div>
          </div>
          <div>
            <label>
              1. Já realizou algum tratamento de Depilação a Laser ou Luz
              Intensa Pulsada?
            </label>
            <div className="option-group">
              <input
                type="radio"
                name="depilacaoLaser"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="depilacaoLaser"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
          </div>

          <div>
            <label>2. Qual o método de depilação atual?</label>
            <div className="option-group">
              <input
                type="radio"
                name="metodoDepilacao"
                value="Cera"
                onChange={handleChange}
              />{" "}
              Cera
              <input
                type="radio"
                name="metodoDepilacao"
                value="Lâminas"
                onChange={handleChange}
              />{" "}
              Lâminas
              <input
                type="radio"
                name="metodoDepilacao"
                value="Outros"
                onChange={handleChange}
              />{" "}
              Outros
            </div>
            {formData.metodoDepilacao === "Outros" && (
              <div>
                <label>Descreva o método:</label>
                <input
                  type="text"
                  name="descriçãoDepilacao"
                  value={formData.descriçãoDepilacao}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          <div>
            <label>
              3. Está sob algum tratamento médico ou estético no momento?
            </label>
            <div className="option-group">
              <input
                type="radio"
                name="tratamentoMedico"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="tratamentoMedico"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.tratamentoMedico === "Sim" && (
              <div>
                <label>Se Sim, descreva:</label>
                <input
                  type="text"
                  name="descricaoTratamento"
                  value={formData.descricaoTratamento}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          <div>
            <label>
              4. Está tomando algum medicamento de uso contínuo ou tomou alguma
              medicação nos últimos 15 dias?
            </label>
            <div className="option-group">
              <input
                type="radio"
                name="usoMedicamento"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="usoMedicamento"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.usoMedicamento === "Sim" && (
              <div>
                <label>Se Sim, descreva:</label>
                <input
                  type="text"
                  name="descricaoMedicamento"
                  value={formData.descricaoMedicamento}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          <div>
            <label>
              5. Está em uso de isotretinoína (Roacutan) via oral ou já tomou
              este medicamento?
            </label>
            <div className="option-group">
              <input
                type="radio"
                name="usoIsotretinoina"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="usoIsotretinoina"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.usoIsotretinoina === "Sim" && (
              <div>
                <label>Data de início e fim do tratamento:</label>
                <input
                  type="text"
                  name="dataIsotretinoina"
                  value={formData.dataIsotretinoina}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>

          <div>
            <label>
              6. Tem alergia de pele (dermatite atópica ou dermatite de contato)
              ou diagnóstico de foliculite decalvante?
            </label>
            <div className="option-group">
              <input
                type="radio"
                name="alergiaPele"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="alergiaPele"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.alergiaPele === "Sim" && (
              <div>
                <label>Se Sim, descreva:</label>
                <input
                  type="text"
                  name="descricaoAlergia"
                  value={formData.descricaoAlergia}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>

          <div>
            <label>
              7. Possui alguma doença autoimune (psoríase, vitiligo, lúpus ou
              líquen plano)? Possui epilepsia?
            </label>
            <div className="option-group">
              <input
                type="radio"
                name="doencaAutoimune"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="doencaAutoimune"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.doencaAutoimune === "Sim" && (
              <div>
                <label>Se Sim, descreva:</label>
                <input
                  type="text"
                  name="descricaoAutoimune"
                  value={formData.descricaoAutoimune}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>

          <div>
            <label>
              8. Tem histórico de câncer ou está em tratamento de câncer?
            </label>
            <div className="option-group">
              <input
                type="radio"
                name="historicoCancer"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="historicoCancer"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.historicoCancer === "Sim" && (
              <div>
                <label>Se Sim, descreva:</label>
                <input
                  type="text"
                  name="descricaoCancer"
                  value={formData.descricaoCancer}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>

          <div>
            <label>
              9. Possui cicatriz hipertrófica, queloide, queimaduras ou enxerto
              cutâneo na área de aplicação do laser?
            </label>
            <div className="option-group">
              <input
                type="radio"
                name="cicatrizQueloide"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="cicatrizQueloide"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.cicatrizQueloide === "Sim" && (
              <div>
                <label>Se Sim, descreva:</label>
                <input
                  type="text"
                  name="descricaoCicatriz"
                  value={formData.descricaoCicatriz}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>

          <div>
            <label>
              10. Possui melasma, rosácea, tatuagens ou micropigmentação na área
              de aplicação do laser?
            </label>
            <div className="option-group">
              <input
                type="radio"
                name="melasmaRosacea"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="melasmaRosacea"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.melasmaRosacea === "Sim" && (
              <div>
                <label>Se Sim, descreva:</label>
                <input
                  type="text"
                  name="descricaoMelasma"
                  value={formData.descricaoMelasma}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>

          <div>
            <label>
              11. Usou ou está usando algum ácido na área a ser tratada?
            </label>
            <div className="option-group">
              <input
                type="radio"
                name="usoAcido"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="usoAcido"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.usoAcido === "Sim" && (
              <div>
                <label>Se Sim, descreva:</label>
                <input
                  type="text"
                  name="descricaoAcido"
                  value={formData.descricaoAcido}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>

          <div>
            <label>
              12. Possui algum implante metálico, prótese no corpo ou
              marca-passo?
            </label>
            <div className="option-group">
              <input
                type="radio"
                name="implanteMetalico"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="implanteMetalico"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.implanteMetalico === "Sim" && (
              <div>
                <label>Se Sim, descreva:</label>
                <input
                  type="text"
                  name="descricaoImplante"
                  value={formData.descricaoImplante}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>

          <div>
            <label>13. Já teve herpes?</label>
            <div className="option-group">
              <input
                type="radio"
                name="herpes"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="herpes"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
          </div>

          <div>
            <label>
              14. Esteve exposto(a) ao sol, utilizou autobronzeadores ou fez
              bronzeamento artificial nos últimos 7 a 30 dias?
            </label>
            <div className="option-group">
              <input
                type="radio"
                name="exposicaoSol"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="exposicaoSol"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
          </div>

          <div>
            <label>
              15. Faz tratamento para reposição hormonal? Já fez ou faz uso de
              anabolizantes?
            </label>
            <div className="option-group">
              <input
                type="radio"
                name="reposicaoHormonal"
                value="Sim"
                onChange={handleChange}
              />{" "}
              Sim
              <input
                type="radio"
                name="reposicaoHormonal"
                value="Não"
                onChange={handleChange}
              />{" "}
              Não
            </div>
            {formData.reposicaoHormonal === "Sim" && (
              <div>
                <label>Se Sim, descreva:</label>
                <input
                  type="text"
                  name="descricaoReposicao"
                  value={formData.descricaoReposicao}
                  onChange={handleChange}
                  required
                />
              </div>
            )}
          </div>
          {/* Pergunta 16 */}
          {formData.sexo === "FEMININO" && (
            <div>
              <label>
                16. Está gestante ou pretende engravidar nos próximos meses?
              </label>
              <div className="option-group">
                <input
                  type="radio"
                  name="gestante"
                  value="Sim, ESTOU"
                  onChange={handleChange}
                />{" "}
                Sim
                <input
                  type="radio"
                  name="gestante"
                  value="Não ESTOU GESTANTE"
                  onChange={handleChange}
                />{" "}
                Não
                <input
                  type="radio"
                  name="gestante"
                  value="PRETENDO ENGRAVIDAR"
                  onChange={handleChange}
                />{" "}
                Pretendo Engravidar
              </div>
            </div>
          )}

          {/* Pergunta 17 */}
          {formData.sexo === "FEMININO" && (
            <div>
              <label>17. Está amamentando?</label>
              <div className="option-group">
                <input
                  type="radio"
                  name="amamentando"
                  value="Sim"
                  onChange={handleChange}
                />{" "}
                Sim
                <input
                  type="radio"
                  name="amamentando"
                  value="Não"
                  onChange={handleChange}
                />{" "}
                Não
              </div>
              {formData.amamentando === "Sim" && (
                <div>
                  <label>Em caso afirmativo, amamenta há quanto tempo:</label>
                  <input
                    type="text"
                    name="tempoAmamentacao"
                    value={formData.tempoAmamentacao}
                    onChange={handleChange}
                    required
                  />
                </div>
              )}
            </div>
          )}

          {/* Pergunta 18 */}
          {formData.sexo === "FEMININO" && (
            <div>
              <label>18. Possui ovário policístico?</label>
              <div className="option-group">
                <input
                  type="radio"
                  name="ovarioPolicistico"
                  value="Sim"
                  onChange={handleChange}
                />{" "}
                Sim
                <input
                  type="radio"
                  name="ovarioPolicistico"
                  value="Não"
                  onChange={handleChange}
                />{" "}
                Não
              </div>
            </div>
          )}
          {formData.sexo === "FEMININO" && (
            <div>
              <label>19. Como você definiria seu fototipo?</label>
              <p>
                Clique na imagem para ver os tipos e selecione o seu. Fique
                tranquila, na Maislaser, nossas esteticistas realizarão uma
                avaliação cuidadosa para confirmar o grupo de fototipo ao qual
                você pertence.{" "}
              </p>
            </div>
          )}
          {formData.sexo === "MASCULINO" && (
            <div>
              <label>16. Como você definiria seu fototipo?</label>
              <p>
                Clique na imagem para ver os tipos e selecione o seu. Fique
                tranquilo, na Maislaser, nossas esteticistas realizarão uma
                avaliação cuidadosa para confirmar o grupo de fototipo ao qual
                você pertence.{" "}
              </p>
            </div>
          )}
          <div style={{ textAlign: "center" }}>
            {/* Imagem que expande e recolhe ao clicar */}
            <img
              src="/images/fototipo.jpg" // Substitua pelo caminho real da imagem
              alt="Fototipo"
              onClick={toggleImageSize}
              style={{
                borderRadius: "5px",
                width: isExpanded ? "300px" : "100px",
                cursor: "pointer",
                transition: "width 0.3s",
              }}
            />

            <div className="option-group" style={{ marginTop: "10px" }}>
              <p>Selecione seu fototipo:</p>
              <ul
                style={{
                  listStyleType: "none",
                  padding: 0,
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <div style={{ display: "flex", gap: "15px" }}>
                  <li>
                    <input
                      type="radio"
                      name="fototipo"
                      value="I"
                      onChange={handleChange}
                      required
                    />{" "}
                    Fototipo I
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="fototipo"
                      value="II"
                      onChange={handleChange}
                      required
                    />{" "}
                    Fototipo II
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="fototipo"
                      value="III"
                      onChange={handleChange}
                      required
                    />{" "}
                    Fototipo III
                  </li>
                </div>
                <div style={{ display: "flex", gap: "15px" }}>
                  <li>
                    <input
                      type="radio"
                      name="fototipo"
                      value="IV"
                      onChange={handleChange}
                      required
                    />{" "}
                    Fototipo IV
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="fototipo"
                      value="V"
                      onChange={handleChange}
                      required
                    />{" "}
                    Fototipo V
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="fototipo"
                      value="VI"
                      onChange={handleChange}
                      required
                    />{" "}
                    Fototipo VI
                  </li>
                </div>
              </ul>
            </div>
          </div>

          <button type="submit">Enviar via WhatsApp</button>
        </form>
      </div>
    </div>
  );
};

export default AnamneseFormDepilacao;
