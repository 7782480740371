// Administrativo.js
import React from "react";
import { Link } from "react-router-dom";
import { useFirebaseAuth } from "./FirebaseAuthContext"; // Importa o hook correto
import "./Administrativo.css";

const Administrativo = () => {
  const { currentUser } = useFirebaseAuth(); // Usa o hook correto

  // Função para capitalizar a primeira letra do username
  const capitalizeFirstLetter = (string) => {
    if (!string) return ""; // Caso o username seja undefined ou null
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <section id="administrativo">
      <div className="administrativo">
        <h2>Painel Administrativo</h2>
        <p>
          Olá{" "}
          {currentUser?.email === "clara.vaccarii@gmail.com"
            ? "Clara"
            : currentUser?.email === "felipe.camarinha@gmail.com"
            ? "Felipe"
            : currentUser?.email === "ml.barradatijuca.con.1@gmail.com"
            ? "Ana"
            : currentUser?.email === "ml.barradatijuca.con.2@gmail.com"
            ? "Carolina"
            : currentUser?.email === "ml.barradatijuca.tec.1@gmail.com"
            ? "Enny"
            : currentUser?.email === "ml.barradatijuca.tec.2@gmail.com"
            ? "Isabella"
            : currentUser?.email === "ml.barradatijuca.coord@gmail.com"
            ? "Ana Carolina"
            : null}{" "}
          , Seja bem-vinda ao painel administrativo. <br />
          Escolha uma das opções abaixo para acessar as funcionalidades
          disponíveis:
        </p>
        <ul>
          {/* Links específicos para técnicos */}
          {[
            "ml.barradatijuca.tec.1@gmail.com",
            "ml.barradatijuca.tec.2@gmail.com",
          ].includes(currentUser?.email) && (
            <>
              <li>
                <Link
                  to="/calculadoraaplicadoras"
                  aria-label="Calculadora para Aplicadoras"
                >
                  Calculadora Aplicadoras:
                </Link>
                <p>
                  Gerencie os orçamentos de aplicadoras de maneira prática e
                  eficiente.
                </p>
              </li>
              <li>
                <Link
                  to="/intercorrencia"
                  aria-label="Fichas de Intercorrência"
                >
                  Fichas de Intercorrência:
                </Link>
                <p>
                  Crie e gerencie fichas de intercorrência de maneira prática e
                  eficiente.
                </p>
              </li>
            </>
          )}
          {/* Links específicos para Consultoras */}
          {[
            "ml.barradatijuca.con.1@gmail.com",
            "ml.barradatijuca.con.2@gmail.com",
          ].includes(currentUser?.email) && (
            <>
              <li>
                <Link
                  to="/calculadoraconsultoras"
                  aria-label="Calculadora para Consultoras"
                >
                  Calculadora Consultoras:
                </Link>
                <p>
                  Realize os orçamentos de consultoras de maneira prática e
                  eficiente.
                </p>
              </li>
            </>
          )}
          {/* Links específicos para Administradores */}
          {[
            "felipe.camarinha@gmail.com",
            "clara.vaccarii@gmail.com",
            "ml.barradatijuca.coord@gmail.com",
          ].includes(currentUser?.email) && (
            <>
              <li>
                <Link
                  to="/calculadoraaplicadoras"
                  aria-label="Calculadora para Aplicadoras"
                >
                  Calculadora Aplicadoras:
                </Link>
                <p>
                  Realize os orçamentos de aplicadoras de maneira prática e
                  eficiente.
                </p>
              </li>
              <li>
                <Link
                  to="/calculadoraconsultoras"
                  aria-label="Calculadora para Consultoras"
                >
                  Calculadora Consultoras:
                </Link>
                <p>
                  Gerencie os orçamentos de consultoras de maneira prática e
                  eficiente.
                </p>
              </li>
              <li>
                <Link to="/controledegestao" aria-label="Controle de Gestão">
                  Controle de Lembretes:
                </Link>
                <p>Gerencie os lembretes seus e de todos os funcionários.</p>
              </li>
              <li>
                <Link to="/consultavendas" aria-label="Consulta de Vendas">
                  Gestão de Vendas:
                </Link>
                <p>Gerencie todas as vendas, gráficos, índices e muito mais.</p>
              </li>
              <li>
                <Link
                  to="/intercorrencia"
                  aria-label="Fichas de Intercorrência"
                >
                  Fichas de Intercorrência:
                </Link>
                <p>
                  Crie e gerencie fichas de intercorrência de maneira prática e
                  eficiente.
                </p>
              </li>
            </>
          )}
          {/* Links específicos para Administradores */}
          {["felipe.camarinha@gmail.com", "clara.vaccarii@gmail.com"].includes(
            currentUser?.email
          ) && (
            <>
              <li>
                <Link to="/gestaoestoque" aria-label="Gestão de Estoque">
                  Gestão de Estoque:
                </Link>
                <p>
                  Gerencie o estoque de produtos de maneira prática e eficiente.
                </p>
              </li>
              <li>
                <Link to="/folhasalarial" aria-label="Folha Salarial">
                  Folha Salarial:
                </Link>
                <p>Gerencie a folha salarial de maneira prática e eficiente.</p>
              </li>
            </>
          )}
          {/* Links específicos para Funcionárias */}
          {[
            "ml.barradatijuca.con.1@gmail.com",
            "ml.barradatijuca.con.2@gmail.com",
            "ml.barradatijuca.tec.1@gmail.com",
            "ml.barradatijuca.tec.2@gmail.com",
          ].includes(currentUser?.email) && (
            <>
              <li>
                <Link to="/user-dashboard" aria-label="Dashboard do usuário">
                  Dashboard do Usuário:
                </Link>
                <p>Visualize suas anotações/lembretes.</p>
              </li>
            </>
          )}

          <li>
            <Link to="/gestaoleads" aria-label="Gestão de leads">
              Gestão de Leads:
            </Link>
            <p>Cadastre e Acompanhe os leads de maneira integrada.</p>
          </li>

          <li>
            <Link to="/vendasporcliente" aria-label="Vendas por Cliente">
              Vendas por Cliente:
            </Link>
            <p>Consulte as vendas dos clientes.</p>
          </li>

          <li>
            <Link
              to="/anamnesedepilacao"
              aria-label="Formulário de anamnese de depilação"
            >
              Anamnese Depilação:
            </Link>
            <p>Envie o formulário de anamnese específicos para depilação.</p>
          </li>
          <li>
            <Link to="/anamneseomer" aria-label="Formulário de anamnese Ômer">
              Anamnese Ômer:
            </Link>
            <p>
              Envie o formulário de anamnese relacionado ao procedimento Ômer.
            </p>
          </li>
          <li>
            <a
              href="https://app.whatssync.com.br"
              target="_blank" // Abre o link em uma nova aba
              rel="noopener noreferrer" // Melhora a segurança
              aria-label="Whatssync"
            >
              WhatsSync:
            </a>
            <p>Entre em contato com nossos clientes, leads e outros.</p>
          </li>
          <li>
            <a
              href="https://app.bellesoftware.com.br"
              target="_blank" // Abre o link em uma nova aba
              rel="noopener noreferrer" // Melhora a segurança
              aria-label="bellesoftware"
            >
              Belle:
            </a>
            <p>Sistema de gestão de vendas, serviços e agendamentos.</p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Administrativo;
