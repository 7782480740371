import React from "react";
//import './GanheSessoes.css';
import emailjs from "emailjs-com";
import "./main.css"; // Estilo para o layout

function GanheSessoes() {
  const handleSubmit = (event) => {
    event.preventDefault();

    const nome = event.target.nome.value;
    const telefone = event.target.telefone.value;
    const email = event.target.email.value;
    const procedimento = event.target.procedimento.value;
    const area = event.target.area.value;

    const customMessage =
      "Olá consultoras :), estava no site de vocês e gostaria de ganhar uma sessão. Vamos marcar a minha consulta e me convencer a comprar algo?%0A%0A";
    const message = `${customMessage}Nome: ${nome}%0ATelefone: ${telefone}%0AEmail: ${email}%0AProcedimento de Interesse: ${procedimento}%0AÁrea de Interesse: ${area}`;

    const whatsappURL = `https://wa.me/5521991261684?text=${message}`;
    window.open(whatsappURL, "_blank");

    // Send data via EmailJS
    emailjs
      .sendForm(
        "service_jhcr7kn",
        "template_ssy6qmq",
        event.target,
        "ifwpKhvtR2dzVE6kr"
      )
      .then(
        (result) => {
          console.log("Email successfully sent:", result.text);
        },
        (error) => {
          console.log("Failed to send email:", error.text);
        }
      );
  };

  return (
    <section id="ganhe-sessoes">
      <div className="ganhe-sessoes-container">
        <div className="ganhe-sessoes-image">
          <img src="/images/voucher.webp" alt="Ganhe Sessões" />
        </div>
        <div className="ganhe-sessoes-form">
          <h2>Ganhe 3 Sessões Gratuitas</h2>
          <form onSubmit={handleSubmit}>
            <label htmlFor="nome">Nome:</label>
            <input
              type="text"
              id="nome"
              name="nome"
              placeholder="Seu nome"
              required
            />

            <label htmlFor="telefone">Telefone:</label>
            <input
              type="tel"
              id="telefone"
              name="telefone"
              placeholder="Seu telefone"
              required
            />

            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Seu e-mail"
              required
            />

            <label htmlFor="procedimento">Procedimento de Interesse:</label>
            <select id="procedimento" name="procedimento" required>
              <option value="depilacao-laser">Depilação a Laser</option>
              <option value="tratamentos">Tratamentos Dermato Estéticos</option>
            </select>

            <label htmlFor="area">Área de Interesse:</label>
            <input
              type="text"
              id="area"
              name="area"
              placeholder="Ex: Axilas, Pernas, etc."
              required
            />

            <button type="submit">Enviar</button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default GanheSessoes;
