import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useFirebaseAuth } from "./FirebaseAuthContext";

const PrivateRoute = ({ children, requiredRoles }) => {
  const { currentUser, customClaims, loading, loadingClaims } =
    useFirebaseAuth();
  const location = useLocation();

  if (loading || loadingClaims) {
    return <p>Carregando...</p>;
  }

  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const userRole = customClaims?.role; // Acesse a role do usuário (com optional chaining)

  if (!userRole || !requiredRoles.includes(userRole)) {
    console.warn(
      "Acesso negado. Role do usuário:",
      userRole,
      "Roles necessárias:",
      requiredRoles
    );
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};

export default PrivateRoute;
