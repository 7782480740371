import React, { useState, useEffect } from "react";
import { db } from "../config/firebase";
import { collection, getDocs } from "firebase/firestore";
import "./VendasPorCliente.css";

const calculateAge = (birthDateString) => {
  const [year, month, day] = birthDateString.split("-");
  const birthDate = new Date(year, month - 1, day);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();
  const dayDiff = today.getDate() - birthDate.getDate();

  // Ajusta a idade se o aniversário ainda não tiver ocorrido este ano
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age;
};

const VendasPorCliente = () => {
  const [clientName, setClientName] = useState("");
  const [sales, setSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState([]);
  const [birthdaysToday, setBirthdaysToday] = useState([]);
  const [showBirthdayModal, setShowBirthdayModal] = useState(false);

  // Função para formatar datas no padrão correto (dd/mm/yyyy)
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const fetchSales = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "sales"));
        const salesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSales(salesData);

        // Filtra aniversariantes do dia
        const today = new Date();
        const todayString = `${String(today.getMonth() + 1).padStart(
          2,
          "0"
        )}-${String(today.getDate()).padStart(2, "0")}`; // Formato MM-DD

        const todaysBirthdays = salesData.filter((sale) => {
          const birthday = sale.aniversario?.split("-");
          return birthday && `${birthday[1]}-${birthday[2]}` === todayString;
        });

        setBirthdaysToday(todaysBirthdays);
        setFilteredSales(todaysBirthdays); // Define a lista inicial como aniversariantes

        if (todaysBirthdays.length > 0) {
          setShowBirthdayModal(true); // Exibe o modal se houver aniversariantes
        }
      } catch (error) {
        console.error("Erro ao buscar vendas:", error);
      }
    };

    fetchSales();
  }, []);

  const filterSales = () => {
    if (clientName.trim() === "") {
      setFilteredSales(birthdaysToday); // Retorna aos aniversariantes se o campo estiver vazio
      return;
    }

    const filtered = sales.filter((sale) =>
      sale.nome.toLowerCase().includes(clientName.toLowerCase())
    );
    setFilteredSales(filtered);
  };

  // Agrupa as vendas por cliente e ordena por data
  const groupedSales = filteredSales.reduce((acc, sale) => {
    if (!acc[sale.nome]) {
      acc[sale.nome] = {
        dataNascimento: sale.aniversario || null,
        vendas: [],
      };
    }
    acc[sale.nome].vendas.push(sale);
    acc[sale.nome].vendas.sort((a, b) => new Date(a.data) - new Date(b.data));
    return acc;
  }, {});

  const formatBirthday = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${day}/${month}`;
  };

  return (
    <section id="vendas-por-cliente">
      {showBirthdayModal && (
        <div className="birthday-modal">
          <div className="birthday-content">
            <button
              className="close-button"
              onClick={() => setShowBirthdayModal(false)}
            >
              ✖
            </button>
            <div className="birthday-cake">🎂</div>
            <h2>🎉 Feliz Aniversário! 🎉</h2>
            <p>Hoje é aniversário dos seguintes clientes:</p>
            {birthdaysToday.map((client) => (
              <p key={client.id}>
                <strong>{client.nome}</strong>
              </p>
            ))}
          </div>
        </div>
      )}

      <div>
        <h2>Vendas por Cliente</h2>
        <input
          type="text"
          placeholder="Digite o nome ou parte dele"
          value={clientName}
          onChange={(e) => setClientName(e.target.value)}
        />
        <button onClick={filterSales}>Filtrar</button>

        <div className="results-container">
          {Object.keys(groupedSales).length > 0 ? (
            Object.entries(groupedSales).map(([clientName, data]) => (
              <div key={clientName} className="client-sales">
                <h3>
                  {clientName}{" "}
                  {data.dataNascimento
                    ? `- Aniversário: ${formatBirthday(
                        data.dataNascimento
                      )} - Idade ${calculateAge(data.dataNascimento)} `
                    : ""}
                </h3>
                {data.vendas.map((sale, index) => (
                  <p key={index}>
                    Comprou {sale.servico1}{" "}
                    {sale.servico2 && `+ ${sale.servico2}`}{" "}
                    {sale.servico3 && `+ ${sale.servico3}`}{" "}
                    {sale.servico4 && `+ ${sale.servico4}`} em{" "}
                    {formatDate(sale.data)}.
                  </p>
                ))}
              </div>
            ))
          ) : clientName.trim() === "" ? (
            <p className="empty-message">
              Nenhum cliente faz aniversário hoje.
            </p>
          ) : (
            <p className="empty-message">
              Nenhuma venda encontrada para o cliente informado.
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default VendasPorCliente;
